import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";


export default function CabofficeBlogs() {
    let [blogs,setBlogs]= useState([])
    useEffect(()=>{

        const handleJobAction = async () => {
            try {
          const token = localStorage.getItem("cabofficeToken");
          let responseBlogs=await axios.get(
            `https://api.minicaberz.com/api/caboffice/cabBlogs`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          console.log(responseBlogs.data,'blogs');
          setBlogs(responseBlogs.data)
        } catch (error) {
            console.error("Error handling job action:", error);
        }
    };
    handleJobAction()
},[])
  return (
    <div className='mt-20 w-[95%] m-auto'>
        <p className='text-[20px] font-semibold mb-3'>BLOGS</p>
        <div className="flex items-center justify-between">

      {blogs.length > 0 ? (
            blogs.map((blog) => (
              <Link to={`/blog/${blog._id}`} key={blog._id} className='w-[32%]'>
                <div className="border h-[54vh] bg-[white] rounded-lg p-4 shadow-lg hover:shadow-xl transition">
                  <img
                    src={`https://api.minicaberz.com${blog.thumbnail}`}
                    alt={blog.title}
                    className="w-full object-cover mb-4"
                  />
                  <div className="w-[100%] mt-2 flex justify-between">
                    <p className="text-gray-600 text-[12px]">{blog.author}</p>
                    <p className="text-gray-500 text-[12px]">
                      {new Date(blog.date).toLocaleDateString()}
                    </p>
                  </div>
                  <h3 className="text-[20px] font-bold text-[#163B68] mt-2">
                    {blog.title}
                  </h3>
                  <button className="mt-5 text-[#2953A1] flex items-center group">
                    Read More{" "}
                    <FaArrowRightLong className="invisible group-hover:ml-2 group-hover:transition-all group-hover:visible" />
                  </button>
                </div>
              </Link>
            ))
          ) : (
            <p>No blogs available</p>
          )}
        </div>
    </div>
  )
}
