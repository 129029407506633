import React from 'react'
import { Outlet } from 'react-router-dom'
import PassengerNavbar from './PassengerNavbar'

export default function PassengerPage() {
  return (
    <div className='flex relative'>
      <PassengerNavbar />
      <Outlet />
    </div>
  )
}

