import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { IoDesktopOutline } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { PiListBulletsBold } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import { CgLogOut } from "react-icons/cg";
import { Link } from "react-router-dom"
import { useAuthContext } from '../../store/auth';


export default function PassengerNavbar() {
  const [dropdown, setdropdown] = useState(false);

  const { isLoggedIn, user } = useAuthContext();
  console.log(isLoggedIn,user, "hello");
  const [data, setData] = useState({
    user_fname: "",
    user_email: "",
  });

  console.log("frontend user ", user);

  const [userData, setUserData] = useState(true);

  if (userData && user) {
    setData({
      user_fname: user.user_fname,
      user_email: user.email,
    });
    setUserData(false);
  }


  const handleClick = () => {
    if(dropdown){
      setdropdown(false)
    }
    else{
      setdropdown(true);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-[100%] h-[8vh] z-10 bg-[#2649AD] flex items-center">
    <div className="w-[100%] flex justify-between items-center bg-[#2649AD] pl-6 pr-6">
      <div className="flex items-center">
        <div className="cursor-pointer">
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <IoDesktopOutline className="mr-2 text-[18px]" /> Dashboard
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink
            to="jobs"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <MdWork className="mr-2 text-[18px]" /> Jobs
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink
            to="book_job"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <MdWork className="mr-2 text-[18px]" /> Book Job
          </NavLink>
        </div>
        
        <div className="cursor-pointer">
          <NavLink
            to="profile"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <FaUserAlt className="mr-2 text-[18px]" /> Profile
          </NavLink>
        </div>
      </div>
      <div
            onClick={handleClick}
            className="cursor-pointer relative pl-2 pr-2 flex items-center justify-center"
          >
            <div className="rounded-full w-[40px] flex items-center justify-center h-[40px] bg-[white] border">
              <FaUserAlt className="text-[25px] text-[#ADB5BD]" />
            </div>

            <p className="text-[16px] text-white ml-2">{isLoggedIn ? data.user_fname : null}</p>

            <div
              className={`${
                dropdown ? "flex" : "hidden"
              } cursor-pointer absolute rounded-xl bg-[white] border border-white items-center justify-center top-[45px] z-30 right-0 pt-2 pb-2 w-[160px] shadow-2xl`}
            >
              <div className="absolute right-[10px] top-[-10px] bg-white admin_nort h-[10px] w-[20px]"></div>
              <CgLogOut className="text-[20px]" />
                <Link to={"/logout-passenger"}>
              <p className="text-[16px] ml-2">Logout</p>
                </Link>
            </div>
          </div>
    </div>
  </div>
  )
}

