import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { useAuthContext } from "../../store/auth";
import { FaPlus } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function AdminBookJob() {

  const [dropdown, setdropdown] = useState(false);
  const navigate = useNavigate();
  const [openViaLocation, setOpenViaLocation] = useState(false);
  const [openButton,setOpenButton]= useState(false)
  const [formData, setFormData] = useState({
    pickupLocation: "",
    viaLocation: "",
    dropLocation: "",
    route: "one_way",
    date: new Date().toISOString().substr(0, 10),
    time: new Date().toISOString().substr(11, 5),
    carType: "saloon",
  });
  const [distance, setDistance] = useState(null);
  const [fare, setFare] = useState(null);

  const pickupInputRef = useRef(null);
  const viaInputRef = useRef(null);
  const dropInputRef = useRef(null);

  const {addJob,fetchJobs} = useAuthContext()

  useEffect(() => {
    // Initialize time on mount
    setFormData((prevData) => ({
      ...prevData,
      time: new Date().toISOString().substr(11, 5),
    }));

    const initAutocomplete = (inputRef, fieldName) => {
      const input = inputRef.current;
      if (!input) return;

      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: place.formatted_address,
        }));
      });
    };

    if (window.google) {
      initAutocomplete(pickupInputRef, "pickupLocation");
      initAutocomplete(viaInputRef, "viaLocation");
      initAutocomplete(dropInputRef, "dropLocation");
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
      script.async = true;
      script.onload = () => {
        initAutocomplete(pickupInputRef, "pickupLocation");
        initAutocomplete(viaInputRef, "viaLocation");
        initAutocomplete(dropInputRef, "dropLocation");
      };
      document.body.appendChild(script);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    
  };

  const calculateDistance = async () => {
    try {
      const destinations = formData.viaLocation
        ? `${formData.viaLocation}|${formData.dropLocation}`
        : formData.dropLocation;

      const response = await axios.get(
        `https://api.minicaberz.com/api/distance?origins=${formData.pickupLocation}&destinations=${destinations}`
      );

      const totalDistanceInMeters = response.data.totalDistance;

      if (typeof totalDistanceInMeters === "number") {
        const totalDistanceInMiles = (totalDistanceInMeters / 1609.34).toFixed(
          2
        );
        console.log("Distance calculated:", totalDistanceInMiles);
        const roundedDistanceInMiles = Math.round(totalDistanceInMiles);
        return roundedDistanceInMiles;
      } else {
        console.error("Invalid distance data:", response.data);
      }
    } catch (error) {
      console.error("Error calculating distance:", error);
    }
  };

  const calculateFare = async (distance, carType) => {
    try {
      const distanceInMiles = parseFloat(distance);

      // Validate if distanceInMiles is a number
      if (isNaN(distanceInMiles)) {
        throw new Error("Invalid distance value");
      }

      console.log(
        "Calculating fare for distance:",
        distanceInMiles,
        "and car type:",
        carType
      );

      const response = await axios.get(
        `https://api.minicaberz.com/api/fares/calculate?carType=${carType}&miles=${distanceInMiles}`
      );

      const fare = response.data;
      let totalFare = fare.firstMilePrice;
      if (distanceInMiles > 1) {
        totalFare += (distanceInMiles - 1) * fare.perMilePrice;
      }
      return totalFare;
    } catch (error) {
      console.error("Failed to calculate fare:", error);
    }
  };
  const handleDone= async (e)=>{
    e.preventDefault()
    const totalDistance = await calculateDistance();
    console.log(totalDistance);
    setDistance(totalDistance)

    if(totalDistance){
      const totalFare = await calculateFare(totalDistance, formData.carType);
        console.log("Total Fare:", totalFare);
        setFare(totalFare)
    }
    setOpenButton(true)
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = { ...formData, distance, fare };
  
    try {
      const response = await axios.post(
        "https://api.minicaberz.com/api/form/",
        dataToSend
      );
  
      if (response.statusText === "Created") {
        // Show success notification or alert
        addJob(response.data.job);
        console.log(response.data.job,'heyyyy');
        alert("Job booked successfully!");
        // navigate('/checkout', { state: { job: response.data.job } });
        // Reset form
        setFormData({
          pickupLocation: "",
          viaLocation: "",
          dropLocation: "",
          route: "one_way",
          date: new Date().toISOString().substr(0, 10),
          time: new Date().toISOString().substr(11, 5),
          carType: "saloon",
        });
        setDistance("");
        setFare("");
        setOpenButton(false);
        fetchJobs()
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      // Show error notification or alert
      alert("Failed to book the job. Please try again.");
    }
  };

  const fetchData = async () => {
    
    try {
      const token1 = localStorage.getItem("adminToken");
      const adminResponse = await axios.get(
        "https://api.minicaberz.com/api/admin/getAdmin",
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      console.log(adminResponse.data);
    //   setadminData(adminResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  //   console.log(jobs);
  const handleClick= ()=>{
    setdropdown(true)
  }
  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin/login");
  };

  return (
    <div className="flex flex-col">
      {/* <AdminNavbar/> */}
      <div className="w-[100%] pl-[300px]">
        <div className="w-[30%] border border-[red] absolute top-[15vh] left-[40%]">
        <div className="w-[100%] pt-8 pb-8 bg-[#253754da] flex flex-col items-center justify-center">
            <input
              type="text"
              name="pickupLocation"
              value={formData.pickupLocation}
              onChange={handleInputChange}
              placeholder="Pickup Location"
              className="w-[90%] rounded-full"
              ref={pickupInputRef}
            />
            <div className="flex items-center w-[90%] mt-2 relative">
              <p className="text-[white] text-[13.5px] pl-2 font-semibold">
                VIA LOCATION
              </p>
              <div className="rounded-full p-1 ml-2 flex items-center justify-center text-white text-[12px] bg-slate-400">
                <FaPlus onClick={() => setOpenViaLocation(true)} />
              </div>
              <FaXmark
                onClick={() => setOpenViaLocation(false)}
                className={`${
                  openViaLocation ? "flex" : "hidden"
                } text-[20px] text-white absolute top-0 right-0`}
              />
            </div>
            <input
              type="text"
              name="viaLocation"
              value={formData.viaLocation}
              onChange={handleInputChange}
              placeholder="Via Location"
              className={`${
                openViaLocation ? "flex" : "hidden"
              } w-[90%] rounded-full mt-1`}
              ref={viaInputRef}
            />
            <input
              type="text"
              name="dropLocation"
              value={formData.dropLocation}
              onChange={handleInputChange}
              placeholder="Drop Location"
              className="w-[90%] mt-2 rounded-full"
              ref={dropInputRef}
            />
            <div className="w-[90%] mt-4 border-t-2 border-white">
              <p className="text-white text-[13.5px] mt-2 pl-2 font-semibold">
                ROUTE
              </p>
              <div className="w-[100%] mt-2 flex flex-col lg:flex-row justify-between font-semibold">
                <select
                  name="route"
                  value={formData.route}
                  onChange={handleInputChange}
                  className="rounded-full p-2 ml-2"
                >
                  <option value="one_way">One way</option>
                  <option value="return">Return</option>
                </select>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="rounded-full ml-2 mt-2"
                  style={{ paddingRight: "1.5em" }}
                />
                <input
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  className="rounded-full ml-2 mt-2 pl-[0] pr-[0]"
                  // style={{ paddingRight: '1.5em' }}
                />
              </div>
            </div>
            <div className="w-[90%] mt-4 border-t-2 border-white">
              <p className="text-white text-[13.5px] mt-2 pl-2 font-semibold">
                CAR TYPE
              </p>
              <div className="w-[100%] mt-2 flex justify-between">
                <select
                  name="carType"
                  value={formData.carType}
                  onChange={handleInputChange}
                  className="text-[13.5px] w-[100%] font-semibold rounded-full ml-2"
                >
                  <option value="saloon">SALOON MAX 4 MAX 2</option>
                  <option value="estate">ESTATE MAX 4 MAX 2</option>
                  <option value="mpv">MPV MAX 4 MAX 2</option>
                  <option value="executive">EXECUTIVE MAX 4 MAX 2</option>
                </select>
              </div>
            </div>
            <div className="w-[90%] mt-4 border-t-2 border-white">
              <div className="w-[100%] mt-2 flex justify-between">
                <p className="text-white">Miles</p><input className="w-[30%]" name="distance" value={distance} type="text" />
                <p className="text-white">Fare</p><input className="w-[30%]" name="fare" value={fare} type="text" />
              </div>
            </div>
            <button
              onClick={handleDone}
            className={`${openButton ? 'hidden' : "flex items-center justify-center"} w-[90%] text-white font-semibold rounded-full mt-4 bg-[#E24A2A] pt-2 pb-2`}
            >
              Get Quote Now
            </button>
            <button
              onClick={handleSubmit}
              className={`${openButton ? 'flex items-center justify-center' : "hidden"} w-[90%] text-white font-semibold rounded-full mt-4 bg-[#E24A2A] pt-2 pb-2`}
            >
              Book Now
            </button>
          </div>
        </div>
      <div  className="w-[100%]">
      <div style={{ height: "95vh", width: "100%" }}>
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509682!2d-0.12775848467915224!3d51.50735027963585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b3a7a60d3b3%3A0x2bc1bf4a9b16218!2sLondon!5e0!3m2!1sen!2suk!4v1619984471072!5m2!1sen!2suk"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        />
      </div>
    </div>
      {/* <AdminJobs /> */}
      </div>
      {/* <AddFareForm /> */}
    </div>
  );
}

export default AdminBookJob;
