import React from "react";
import AirpSec2Img from "./Images/img5.jpg";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaGem } from "react-icons/fa6";
import { FaRegFlag } from "react-icons/fa";
import { FaRegThumbsUp } from "react-icons/fa";
import img1 from "../Components/Images/London-City-Airport-LCY.jpg";
import img2 from "../Components/Images/heathrow-airport.avif";
import img3 from "../Components/Images/luton-airport.avif";
import img4 from "../Components/Images/southendairport.avif";
import img5 from "../Components/Images/gatwick-airport.webp";
import img6 from "../Components/Images/stansted-airport.jpg";
import { Link } from "react-router-dom";

export default function AirportSec2() {
  return (
    <div className="w-[100%] pb-7">
      <p className="conteiner mb-7 text-[25px] text-center font-semibold">
        Airport Transfers
      </p>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto flex justify-between flex-wrap pb-10">
        <div className="w-[95%] sm:w-[48%] lg:w-[31%]">
          <Link
            to={"londoncity-airport"}
            className="h-[max-content] relative group airport-img"
          >
            <img src={img1} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON CITY AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
            LONDON CITY AIRPORT
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
            With flexible scheduling, real-time flight tracking, and reliable vehicles, we ensure you arrive on time and in comfort.
          </p>
        </div>
        <div className="w-[95%] sm:w-[48%] lg:w-[31%]">
          <Link to={"stansted-airport"} className="h-[max-content] relative group airport-img">
            <img src={img6} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON STANSTED AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
          STANSTED AIRPORT TRANSFER
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
          Minicaber offers a stress-free and comfortable ride to and from Stansted Airport.Choose us for a hassle-free airport experience.
          </p>
        </div>
        <div className="w-[95%] sm:w-[48%] lg:w-[31%]">
          <Link to={"heathrow-airport"} className="h-[max-content] relative group airport-img">
            <img src={img2} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON HEATHROW AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
          HEATROW AIRPORT TRANSFER
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
          Our Heathrow airport taxi transfer service ensures you experience a smooth and comfortable journey, whether you are arriving or departing.
          </p>
        </div>
        <div className="w-[95%] sm:w-[48%] lg:w-[31%]  mt-8">
          <Link to={"luton-airport"} className="h-[max-content] relative group airport-img">
            <img src={img3} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON LUTON AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
          LUTON AIRPORT TRANSFER
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
          Enjoy a comfortable ride and competitive rates when you book your Luton airport transfer with us. We make sure you reach your destination on time, every time.
          </p>
        </div>
        <div className="w-[95%] sm:w-[48%] lg:w-[31%] mt-8">
          <Link to={"southend-airport"} className="h-[max-content] relative group airport-img">
            <img src={img4} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON SOUTHEND AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
          SOUTHEND AIRPORT TRANSFER
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
          Enjoy a reliable, door-to-door transfer service tailored to your schedule, ensuring you can focus on your trip without worrying about transport logistics.
          </p>
        </div>
        <div className="w-[95%] sm:w-[48%] lg:w-[31%] mt-8">
          <Link to={"gatwick-airport"} className="h-[max-content] relative group airport-img">
            <img src={img5} className="w-[100%] h-[40vh]" alt="" />
            <div className="absolute top-0 left-0 w-[100%] h-[100%] flex group-hover:opacity-100 group-hover:pb-[20px] opacity-0 items-end justify-center bg-[#0000009c]">
              <p className="text-[20px] font-semibold text-white">
                LONDON GATWICK AIRPORT
              </p>
            </div>
          </Link>
          <p className="text-center text-[24px] font-semibold mt-4">
          GATWICK AIRPORT TRANSFER
          </p>
          <p className="text-center text-[18px] mt-1 text-[rgb(65, 65, 65)]">
          We provide transfers to and from Gatwick’s North and South terminals, ensuring a smooth journey every time.
          </p>
        </div>
      </div>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto flex flex-wrap items-center justify-center md:justify-between">
        <div className="w-[95%] sm:w-[100%] md:w-[53%]">
          <p className="text-[25px] font-semibold text-center">
            Airport Transfer Specialist In London
          </p>
          <p className="text-[16px] mt-3">
            Minicaberz London is a leading airport transfer company that
            specializes in providing 24-hour service to and from various
            airports in London, including Heathrow Airport, Stansted Airport,
            Gatwick Airport, Luton Airport, and London City Airport. With their
            reliable and efficient service, Minicaberz London ensures that
            passengers have a smooth and comfortable journey to and from the
            airport. One of the key advantages of Minicaberz London is their
            round-the-clock availability. They understand that flights can
            arrive or depart at any time, and passengers may require
            transportation outside of regular business hours. Whether it’s an
            early morning flight or a late-night arrival, Minicaberz London is
            there to meet the needs of travelers, providing prompt and reliable
            airport transfers throughout the day and night. Minicaberz London
            prides itself on its fleet of well-maintained vehicles. Our modern
            and comfortable cars ensure a pleasant travel experience for
            passengers. Each vehicle is equipped with the latest technology,
            including GPS navigation systems, ensuring that drivers can
            efficiently navigate through London’s busy streets, taking the most
            suitable routes to and from the airports. Additionally, their
            vehicles have ample space to accommodate luggage, making them
            suitable for both individual travelers and groups. Minicaberz London
            has established a strong reputation in the airport transfer
            industry, with positive reviews from satisfied customers. Our
            commitment to providing excellent service, reliability, and
            convenience has made them a trusted choice for travelers seeking
            seamless airport transfers in London.
          </p>
        </div>
        <div className="w-[95%] sm:w-[100%] md:w-[45%]">
          <img className="w-[100%] rounded-lg" src={AirpSec2Img} alt="" />
        </div>
      </div>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto mt-6 flex flex-wrap justify-center lg:justify-between">
        <div className="w-[95%] sm:w-[100%] lg:w-[49%] p-7 mt-4 lg:mt-0 card_shadow group">
          <div className="airpCard bg-[#E5E7EB] group-hover:bg-[#2953A1] w-[120px] h-[120px] flex items-center justify-center mt-[-28px] mr-[-28px]">
            <FaRegCheckCircle className="text-[50px] ml-4 mb-6 group-hover:text-[white]" />
          </div>
          <p className="text-[21px] font-semibold">
            Your Premier Airport Transfer Provider in London
          </p>
          <p className="text-[16px] mt-4">
            Welcome to Minicaberz, your trusted airport transfer service
            catering to travelers in London and beyond. We specialize in
            providing seamless and reliable transportation to and from major
            airports, including Heathrow Airport, Stansted Airport, Gatwick
            Airport, London City Airport, and Luton Airport. With our commitment
            to excellence and customer satisfaction, we strive to make your
            airport transfer experience convenient, comfortable, and
            stress-free.
          </p>
        </div>
        <div className="w-[95%] sm:w-[100%] lg:w-[49%] p-7 mt-4 lg:mt-4 card_shadow group">
          <div className="airpCard bg-[#E5E7EB] group-hover:bg-[#2953A1] w-[120px] h-[120px] flex items-center justify-center mt-[-28px] mr-[-28px]">
            <FaGem className="text-[50px] ml-4 mb-6 group-hover:text-[white]" />
          </div>
          <p className="text-[21px] font-semibold">
            Convenient Airport Transfers
          </p>
          <p className="text-[16px] mt-4">
            We understand the importance of punctuality when it comes to air
            travel. That’s why we offer timely and convenient airport transfers,
            ensuring you arrive at your destination on time. Whether you’re
            traveling for business or pleasure, our professional chauffeurs will
            be there to greet you at the airport, assist with your luggage, and
            provide a comfortable journey to your desired location.
          </p>
        </div>
        <div className="w-[95%] sm:w-[100%] lg:w-[49%] p-7 card_shadow group mt-6">
          <div className="airpCard bg-[#E5E7EB] group-hover:bg-[#2953A1] w-[120px] h-[120px] flex items-center justify-center mt-[-28px] mr-[-28px]">
            <FaRegFlag className="text-[50px] ml-4 mb-6 group-hover:text-[white]" />
          </div>
          <p className="text-[21px] font-semibold">Covering Major Airports</p>
          <p className="text-[16px] mt-4">
            No matter which airport you’re arriving at or departing from in
            London, Minicaberz has got you covered. From the bustling terminals
            of Heathrow Airport to the efficient operations of Stansted Airport,
            Gatwick Airport, London City Airport, and Luton Airport, our
            experienced drivers are familiar with the unique requirements of
            each airport, ensuring a seamless and hassle-free transfer
            experience.
          </p>
        </div>
        <div className="w-[95%] sm:w-[100%] lg:w-[49%] p-7 card_shadow group mt-6">
          <div className="airpCard bg-[#E5E7EB] group-hover:bg-[#2953A1] w-[120px] h-[120px] flex items-center justify-center mt-[-28px] mr-[-28px]">
            <FaRegThumbsUp className="text-[50px] ml-4 mb-6 group-hover:text-[white]" />
          </div>
          <p className="text-[21px] font-semibold">
            Reliability and Professionalism
          </p>
          <p className="text-[16px] mt-4">
            At Minicaberz, we pride ourselves on our commitment to reliability
            and professionalism. Our drivers are carefully selected and
            well-trained, ensuring a safe and comfortable journey for our
            passengers. With our modern and well-maintained fleet of luxury
            vehicles, you can relax and enjoy a smooth ride while our
            experienced chauffeurs navigate the bustling streets of London.
          </p>
        </div>
      </div>
    </div>
  );
}

