import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../store/auth";

export const LogoutPassenger = () => {
  const { LogoutUser } = useAuthContext();

  useEffect(() => {
    LogoutUser();
  }, [LogoutUser]);

  return <Navigate to="/" />;
};