import React from 'react'

export default function Section2() {
  return (
    <div className='w-[100%] pt-12 pb-12 sec2_img flex flex-col items-center justify-center'>
      <div className="w-[50%] pt-5 pb-5 rounded-xl bg-white flex flex-col items-center justify-center">
        <p className='text-[35px] w-[80%] text-center font-semibold'>Always pre book your airport transfer to get hassle free service</p>
        <a href="#">
          <button className='bg-[#2953A1] mt-4 text-[white] font-semibold rounded-lg pl-6 pr-6 pt-2 pb-2'>Book Now</button>
          
          </a>
      </div>
    </div>
  )
}


