import React,{useState} from "react";
import AirpSec2Img from "./Images/img5.jpg";
import { GoDotFill } from "react-icons/go";
import { useAuthContext } from "../store/auth";

export default function AboutSec2() {
  const [data, setData] = useState({
    user_fname:'',
    user_email:''
  });

  const { user } = useAuthContext();

  console.log("frontend user ", user);

  const [userData, setUserData] = useState(true);

  if (userData && user) {
    setData({
      user_fname: user.user_fname,
      user_email: user.email,
      // message: "",
    });
    setUserData(false);
  }
  return (
    <div className="w-[100%] mt-14 pb-14">
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto flex flex-wrap items-center justify-center md:justify-between">
        <div className="w-[95%] sm:w-[100%] md:w-[53%]">
          <p className="text-[25px] font-semibold text-center">
            {data.user_fname} Professional Minicaberz Airport Transfer and Executive Car Hire
          </p>
          <p className="text-[16px] mt-3">
            Welcome to Minicaberz, a leading provider of premier airport transfer
            and executive car hire services in London. With a commitment to
            excellence and a focus on delivering unparalleled customer
            satisfaction, we strive to exceed your expectations with every
            journey.At Minicaberz, we understand that your travel experience
            begins the moment you step out of your door. That’s why we go the
            extra mile to ensure a seamless and luxurious transportation service
            from start to finish. Whether you’re heading to the airport or
            require executive car hire for a special occasion, our dedicated
            team is here to cater to your every need.
          </p>
          <p className="text-[16px] mt-3">
            Our fleet of meticulously maintained luxury vehicles is designed to
            provide you with utmost comfort and style. From sleek sedans to
            spacious SUVs, each vehicle is equipped with modern amenities and
            features to enhance your journey. Rest assured, our professional and
            experienced chauffeurs will navigate the busy streets of London,
            ensuring a smooth and enjoyable ride.
          </p>
          <p className="text-[16px] mt-3">
            What sets us apart is our unwavering commitment to delivering
            exceptional customer service. We prioritize your satisfaction and
            strive to make every interaction with Minicaberz a positive and
            memorable experience. Our team of customer service professionals is
            available around the clock to assist you with any queries or
            requests you may have.
          </p>
        </div>
        <div className="w-[95%] sm:w-[100%] md:w-[45%]">
          <img className="w-[100%] rounded-lg" src={AirpSec2Img} alt="" />
        </div>
      </div>
      <div className="w-[100%] mt-8">
        <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto flex items-center justify-between">
          <div className="w-[49%]">
            <p className="text-[16px] mt-3">
              Our core values of professionalism, reliability, and integrity
              guide everything we do. We are dedicated to maintaining the
              highest standards of quality and safety, ensuring that your
              journey with Minicaberz is nothing short of exceptional. Whether
              you need a reliable airport transfer or executive car hire for a
              business meeting, wedding, or special event, we will work closely
              with you to accommodate your needs and preferences.
            </p>
            <p className="text-[16px] mt-3">
              Experience the difference with Minicaberz and enjoy a stress-free
              and luxurious transportation service in London. Book your next
              airport transfer or executive car hire with us and discover why we
              are the preferred choice for discerning travelers. Sit back,
              relax, and let us take care of your transportation needs, allowing
              you to focus on what matters most. Welcome aboard Minicaberz, where
              luxury and reliability meet for an unforgettable journey.
            </p>
            <p className="text-[16px] mt-3">
              Choose Minicaberz for your airport transfers and executive car hire
              needs, and experience the pinnacle of luxury, professionalism, and
              reliability. Book your next journey with us and discover why we
              are the preferred choice for discerning travelers in London.Our
              dedicated customer support team is available to assist you every
              step of the way, ensuring a seamless experience and addressing any
              questions or concerns you may have.
            </p>
            <p className="text-[16px] mt-3">
              We closely monitor flight schedules to adjust your pickup time in
              case of delays or early arrivals, ensuring a hassle-free and
              timely transportation experience.Our drivers will pick you up from
              your doorstep and drop you off at your desired destination,
              offering a convenient and hassle-free door-to-door service. From
              the cleanliness of our vehicles to the professionalism of our
              chauffeurs, we pay attention to every detail to provide you with a
              superior and memorable experience
            </p>
          </div>
          <div className="w-[49%] bg-[#2953A1] rounded-lg p-5">
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              Luxury Fleet: Enjoy the comfort and style of our meticulously
              maintained fleet of luxury vehicles, including sleek sedans and
              spacious SUVs, ensuring a luxurious and sophisticated travel
              experience.
            </p>
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              Professional Chauffeurs: Our experienced and professional
              chauffeurs are well-trained to provide exemplary service, ensuring
              a smooth and enjoyable journey for our clients.
            </p>
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              Punctuality: We understand the importance of timeliness. With
              Minicaberz, you can trust that our drivers will arrive promptly,
              ensuring you never miss a flight or arrive late to your
              destination
            </p>
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              Reliable Airport Transfers: Our airport transfer service
              guarantees seamless transportation to and from all major airports
              in the London area, taking the stress out of travel and ensuring a
              comfortable and efficient journey.
            </p>
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              Executive Car Hire: Make a lasting impression with our executive
              car hire service. Whether it’s a business meeting, corporate
              event, or special occasion, our premium vehicles and professional
              chauffeurs will elevate your experience.
            </p>
            <p className="text-[16px] mt-4 text-white flex">
              <GoDotFill className="text-[30px] mr-3" />
              24/7 Availability: We are available around the clock to
              accommodate your transportation needs, ensuring convenience and
              flexibility for your travel plans.Personalized Service: We
              understand that each client is unique. That’s why we offer
              personalized service, tailoring our offerings to meet your
              specific requirements and preferences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

