import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useAuthContext } from "../store/auth";

const BlogCards = () => {
  const {blogs, setBlogs} = useAuthContext()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
  const fetchBlogs = async (page = 1) => {
    try {
      const response = await fetch(
        `https://api.minicaberz.com/api/admin/blogs?page=${page}&limit=3`
      );
      const data = await response.json();
      setBlogs(data.blogs);
      setTotalPages(data.totalPages);
      setCurrentPage(parseInt(data.currentPage));
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
    fetchBlogs(currentPage);

  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="w-[100%] bg-[#F7F8FC] pt-12 pb-12">
      <p className="text-[35px] text-center font-bold mb-7">Blogs</p>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {blogs.length > 0 ? (
            blogs.map((blog) => (
              <Link to={`/blog/${blog._id}`} key={blog._id}>
                <div className="border bg-[white] rounded-lg p-4 shadow-lg hover:shadow-xl transition h-full">
                  <img
                    src={`https://api.minicaberz.com${blog.thumbnail}`}
                    alt={blog.title}
                    className="w-full object-cover mb-4"
                  />
                  <div className="w-[100%] mt-2 flex justify-between">
                    <p className="text-gray-600 text-[12px]">{blog.author}</p>
                    <p className="text-gray-500 text-[12px]">
                      {new Date(blog.date).toLocaleDateString()}
                    </p>
                  </div>
                  <h3 className="text-[20px] font-bold text-[#163B68] mt-2">
                    {blog.title}
                  </h3>
                  <button className="mt-5 text-[#2953A1] flex items-center group">
                    Read More{" "}
                    <FaArrowRightLong className="invisible group-hover:ml-2 group-hover:transition-all group-hover:visible" />
                  </button>
                </div>
              </Link>
            ))
          ) : (
            <p>No blogs available</p>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-16">
          <button
            className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {[...Array(totalPages).keys()].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 mx-2 ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } rounded`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;

