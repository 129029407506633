import React from 'react'

export default function UpperNavbar() {
  return (
    <div className='w-[100%] flex items-center justify-center bg-[#112759] text-white upper_navbar pt-2 pb-2'>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] flex items-center justify-between ">
        <p className='text-[18px] font-medium'>24 HOUR TAXI SERVICE AND AIRPORT TRANSFER</p>
        <p className='text-[18px] font-medium'>Reliable, Affordable, Ontime, Hassle Free</p>
      </div>
    </div>
  )
}

