import React from "react";
import { HiMiniXMark } from "react-icons/hi2";

export default function ViewJob({ ViewJobs, onClose }) {
  if (!ViewJobs || ViewJobs.length === 0) {
    return null; // No job to view
  }
  const job = ViewJobs[0]; // Since ViewJobs is an array, let's grab the first job
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="w-full h-[max-content] mt-11 max-w-[600px] mx-4 bg-white rounded-lg shadow-2xl transform transition-all duration-300 ease-in-out scale-95">
        {/* Header */}
        <div className="bg-gradient-to-r bg-[#2B55A1] text-white flex justify-between items-center px-6 py-4 rounded-t-lg shadow-md">
          <h2 className="text-2xl font-bold">JobId {job.jobId}</h2>
          <HiMiniXMark
            className="text-2xl cursor-pointer hover:text-gray-200 transition-colors duration-200 ease-in-out"
            onClick={onClose}
          />
        </div>

        {/* Content */}
        <div className="p-6 space-y-5">
          <div className="flex items-center space-x-4">
            <div className="flex flex-grow">
            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              From
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.pickupLocation}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              To
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.dropLocation}
            </p>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="flex flex-grow">
            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Phone
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.phoneNumber}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Car
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.carType}
            </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex flex-grow">
            <label className="w-[80px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Date
            </label>
            <p
              className=" bg-gray-100 p-1 pr-6 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {formatDate(job.date)}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[80px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Time
            </label>
            <p
              className="bg-gray-100 p-1 pr-6 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.time}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[80px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Route
            </label>
            <p
              className="bg-gray-100 p-1 pr-6 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.route}
            </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex flex-grow">
            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Name
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.name}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Message
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.message}
            </p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex flex-grow">
            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Fare
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.fare}
            </p>
            </div>
            <div className="flex flex-grow">

            <label className="w-[90px] h-[100%] border border-[#2B55A1] bg-gradient-to-r bg-[#2B55A1] text-white text-center p-1.5 rounded-l-md text-[14px] font-semibold">
              Miles
            </label>
            <p
              className="flex-grow bg-gray-100 p-1 text-[15px] rounded-r-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
            >
                {job.distance}
            </p>
            </div>
          </div>
          
          
          
          
          
        </div>

        {/* Buttons */}
        <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-4 rounded-b-lg">
          
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-5 py-2 rounded-md shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-200 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </div>
  );
}
