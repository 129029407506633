import React, { useState, useEffect } from "react";
import SliderImage1 from "./Images/banner.png";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    {
      image1: SliderImage1,
      p1: "24/7 Hassle Free Minicab Service",
      p2: "Quick ,Fast, Affordable and Reliable",
    },
    {
      image1: SliderImage1,
      p1: "Airport Transfer Minicab Service",
      p2: "Book Your Taxi To Any London Airport On Short Notice",
    },
    {
      image1: SliderImage1,
      p1: "Reach Your Destination on time",
      p2: "From all London airports to London & across the UK",
    },
  ];

  const back = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex <= 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const next = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <article className="relative w-full h-[100%] flex flex-shrink-0 overflow-hidden shadow-2xl">
      {images.map((image, index) => (
        <figure
          key={index}
          className={`absolute inset-0 z-10 h-full w-full object-cover ${
            currentIndex === index ? "opacity-100" : "opacity-0"
          } transition transform duration-300`}
        >
          <img
            className="w-[100%] h-[100%]"
            src={image.image1}
            alt={`Slide ${index + 1}`}
          />
          <figcaption className="absolute flex items-center justify-center inset-x-0 top-0 left-0 z-20 w-[100%] h-[100%]  bg-opacity-25">
            <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] flex items-center justify-start">
              
              <div className="hidden sm:flex w-[63%] flex-col justify-end">
                <p className="font-semibold text-[17px] md:text-[20px] text-white text-left mt-2">
                  {image.p2}
                </p>
                <p className="font-semibold text-[38px] md:text-[52px] leading-[49px] lg:text-[45px] lg:leading-[65px] text-white text-left">
                  {image.p1}
                </p>
                <p className="text-[17px] md:text-[20px] text-white text-left mt-2">
                Experience a high-quality taxi service with Minicaberz. Whether it’s a local ride, a day out, or a reliable airport transfer, Minicaber is here to make your journey enjoyable and stress-free. Book your taxi today and discover the convenience of professional transport services. We offer professional minicab services in London, perfect for everyday travel. 
                </p>
              </div>
            </div>
          </figcaption>
        </figure>
      ))}
    </article>
  );
};

export default Slider;

