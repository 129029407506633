import React from "react";
import { FaHandshake } from "react-icons/fa";
import { FaShieldAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa";
import { GiMedal } from "react-icons/gi";
import { AiOutlineCompass } from "react-icons/ai";

export default function Section1() {
  return (
    <div className="w-[100%] pt-14 pb-8 flex items-center justify-center">
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] flex flex-col items-center justify-center">
        <p className="text-[35px] font-bold">Why Choose Minicaber London</p>

        <div class="py-10 bg-opacity-10 ">
          <div class="grid grid-cols-1 md:lg:xl:grid-cols-4 group bg-white shadow-xl shadow-neutral-100 ">
            <div class="p-10 flex flex-col items-center text-center group border md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
                <FaHandshake className="text-[35px]" />
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Trusted & Reliable
              </p>
              <p class="mt-2 text-sm text-slate-500">
              Minicaber prioritize always on time pickups and drop-offs, ensuring our passengers don't miss their flights or experience delays after landing
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group border md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200">
                <FaShieldAlt className="text-[35px]" />
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                FIXED PRICE NO SURGE
              </p>
              <p class="mt-2 text-sm text-slate-500">
              At Minicaber we have a straight fixed pricing without any surge in fares in traffic routes. We are charging the standard fixed fares.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group border  md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200">
                <FaRegClock className="text-[35px]" />
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                24/7 AVAILABILITY
              </p>
              <p class="mt-2 text-sm text-slate-500">
              No matter the time of day, we are always available to serve you with round-the-clock taxi and minicab services.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group border  md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-lime-500 text-white shadow-lg shadow-lime-200">
                <FaRegCreditCard className="text-[35px]" />
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">Convenience</p>
              <p class="mt-2 text-sm text-slate-500">
              Our passengers can enjoy a stress-free ride with our professional drivers, spacious cars, and hassle free airport pick-ups and drop-off.
              </p>
            </div>

            {/* <div class="p-10 flex flex-col items-center text-center group  border  md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
                <span class="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200"><GiMedal className='text-[35px]' /></span>
                <p class="text-xl font-medium text-slate-700 mt-3">Loyalty Program</p>
                <p class="mt-2 text-sm text-slate-500">We are always taking care of our customer with discounts and frequent user loyalty rewards on points.</p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group border   hover:bg-slate-50 cursor-pointer">
                <span class="p-5 rounded-full bg-indigo-500 text-white shadow-lg shadow-indigo-200"><AiOutlineCompass className='text-[35px]' /></span>
                <p class="text-xl font-medium text-slate-700 mt-3">Real Time ETA</p>
                <p class="mt-2 text-sm text-slate-500">Allow your customers to track their driver’s real-time location and see their updated arrival times.
                </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

