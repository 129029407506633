import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [user, setUser] = useState(null); // Initialize user as null
    const [UserJobs, setuserJobs] = useState([]); // Initialize user as null
    const [jobs, setJobs] = useState([]);
    const [blogs, setBlogs] = useState([]);

    const storeTokenInLS = (serverToken) => {
        setToken(serverToken);
        localStorage.setItem("token", serverToken);
    };

    const isLoggedIn = !!token;

    const LogoutUser = () => {
        setToken(null);
        localStorage.removeItem("token");
        setUser(null);
    };

    const userAuthentication = async () => {
        if (!token) return;

        try {
            const response = await axios.get('https://api.minicaberz.com/api/auth/user', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data.userData); // Set user data
            console.log(user,'usersssss');
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            if (error.response?.status === 401) {
                LogoutUser();
            }
        }
    };
    const getuserJobs = async () => {
        if (!token) return;

        try {
            const response = await axios.get('https://api.minicaberz.com/api/auth/jobs', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setuserJobs(response.data.jobData); // Set user data
            console.log(response.data.jobData,'jobssssss');
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            
        }
    };

    const fetchJobs = async () => {
        try {
            const jobtoken = localStorage.getItem("adminToken");
            const jobResponse = await axios.get(
                "https://api.minicaberz.com/api/admin/jobs",
                {
                    headers: {
                        Authorization: `Bearer ${jobtoken}`,
                    },
                }
            );
            setJobs(jobResponse.data);
            // console.log(jobResponse.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    const addJob = async (newJob) => {
        // Prepare job with user's first name if user is logged in
        const jobWithUser = { ...newJob, userFirstName: user ? user.user_fname : 'Guest' };
        try {
            const jobtoken = localStorage.getItem("adminToken");
            // Send job data to backend
            await axios.post("https://api.minicaberz.com/api/form/", jobWithUser, {
                headers: {
                    Authorization: `Bearer ${jobtoken}`,
                },
            });
            // Update local state after successful backend save
            setJobs((prevJobs) => [...prevJobs, jobWithUser]);
        } catch (error) {
            console.error('Error adding job:', error);
        }
    };
    const updateJob = async (jobId, jobDetails) => {
        await fetch(`https://api.minicaberz.com/api/jobs/${jobId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jobDetails),
        });
      };
      const updateJobstatus = async (jobId, updatedFields) => {
        try {
          const response = await axios.put(`https://api.minicaberz.com/api/form/${jobId}/cancel`, updatedFields);
          setJobs((prevJobs) =>
            prevJobs.map((job) =>
              job._id === jobId ? { ...job, ...response.data } : job
            )
          );
        } catch (error) {
          console.error('Error updating job:', error);
        }
      };
      const passJobstatus = async (jobId, updatedFields) => {
        try {
          const response = await axios.put(`https://api.minicaberz.com/api/form/${jobId}/pass`, updatedFields);
          setJobs((prevJobs) =>
            prevJobs.map((job) =>
              job._id === jobId ? { ...job, ...response.data } : job
            )
          );
        } catch (error) {
          console.error('Error updating job:', error);
        }
      };
      
      const deleteJob = async (jobId) => {
        await fetch(`https://api.minicaberz.com/api/jobs/${jobId}`, {
          method: 'DELETE',
        });
      };

    useEffect(() => {
        if (token) {
            userAuthentication();
            fetchJobs();
            getuserJobs()
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, storeTokenInLS, LogoutUser, user, jobs, addJob, fetchJobs,updateJob,deleteJob, updateJobstatus,passJobstatus,blogs,setBlogs,UserJobs,getuserJobs }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    return useContext(AuthContext);
};

