import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import AirportSec1 from '../Components/AirportSec1'
import AirportSec2 from '../Components/AirportSec2'
import Footer from '../Components/Footer'
import UpperNavbar from '../Components/UpperNavbar'

export default function AirportTransfers() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='w-[100%] p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <AirportSec1 />
      <AirportSec2 />
      <Footer />
    </div>
  )
}

