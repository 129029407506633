import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const CabOfficeViewjob = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();  // Add useNavigate hook
  const token = localStorage.getItem("cabofficeToken");

  useEffect(() => {
    axios
      .get(`https://api.minicaberz.com/api/caboffice/jobs/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setJob(response.data.job);
      })
      .catch((error) => {
        setError(error.response?.data?.message || "Failed to load job");
      });
  }, [jobId, token]);

  const handleJobAction = async (jobId, action) => {
    try {
      await axios.post(
        `https://api.minicaberz.com/api/caboffice/jobs/${jobId}/action`,
        { action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Action successful');
      
      if (action === "accept") {
        navigate('/caboffice/dashboard');  // Redirect to the dashboard
      }
      
    } catch (error) {
      console.error("Error handling job action:", error);
    }
  };

  if (error) {
    return <div className="w-[100%] bg-[#193364] pt-12 pb-12 h-[100vh] flex flex-col items-center text-white"><p className="w-[700px] font-bold text-[18px]">{error}</p><p className="w-[700px] mt-4">You appear to have tried to access a link that no longer exist. This may be a result of following an out of data.</p></div>;
  }

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-[100%] bg-[#193364] pt-12 pb-12 h-[100vh] flex justify-center">
      <div className="w-[700px] text-white pl-9 pr-10 pt-9">
      <h2 className="text-[#f1c40f] text-[20px] font-semibold">Journey Quotation Request</h2>
          <p className="text-[15px] mt-[20px]">
            We would like to know availability for {job.jobId}. Please use the link below to see the full details and respond.
          </p>
          <p className="text-15px font-semibold mt-[20px]">
            We believe you would be suitable due to your location, vehicle type(s), and the potential to tie in-with another journey.
          </p>
      <h1 className="text-2xl mb-4 mt-4 font-semibold">Job Details</h1>
      <p className="flex justify-between text-[18px]"><strong>Pickup Location:</strong> {job.pickupLocation}</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Drop Location:</strong> {job.dropLocation}</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Date:</strong> {job.date}</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Time:</strong> {job.time}</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Distance:</strong> {job.distance} miles</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Fare:</strong> £ {job.fare}</p>
      <p className="flex justify-between text-[18px] mt-4"><strong>Note:</strong> {job.message}</p>
      <div className="mt-6 text-right">
        <button onClick={() => handleJobAction(job._id, "reject")} className="bg-red-500 text-white px-4 py-2 rounded">Reject</button>
        <button onClick={() => handleJobAction(job._id, "accept")} className="bg-green-500 text-white px-4 py-2 rounded ml-4">Accept</button>
      </div>
      </div>
    </div>
  );
};

export default CabOfficeViewjob;
