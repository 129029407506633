import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute3 = ({ children }) => {
  const token = localStorage.getItem('token');

  return token ? children : <Navigate to="/login-passenger" />;
};

export default ProtectedRoute3;
