import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { IoDesktopOutline } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { PiListBulletsBold } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import { CgLogOut } from "react-icons/cg";
import { useNavigate } from "react-router-dom";


export default function CabOfficeNavbar() {
  const [cabOfficer, setcabOfficer] = useState({});
  
  const [dropdown, setdropdown] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("cabofficeToken");
  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(
          "https://api.minicaberz.com/api/caboffice/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setcabOfficer(response.data.cabOffice);
        console.log(response.data.cabOffice);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchData();

    
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("cabofficeToken");
    navigate("/");
  };

  const handleClick = () => {
    if(dropdown){
      setdropdown(false)
    }
    else{
      setdropdown(true);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-[100%] h-[8vh] z-10 bg-[#2649AD] flex items-center">
    <div className="w-[100%] flex justify-between items-center bg-[#2649AD] pl-6 pr-6">
      <div className="flex items-center">
        <div className="cursor-pointer">
          <NavLink
            to="dashboard"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <IoDesktopOutline className="mr-2 text-[18px]" /> Dashboard
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink
            to="jobs"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <MdWork className="mr-2 text-[18px]" /> Jobs
          </NavLink>
        </div>
        
        <div className="cursor-pointer">
          <NavLink
            to="profile"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <FaUserAlt className="mr-2 text-[18px]" /> Profile
          </NavLink>
        </div>
        
        <div className="cursor-pointer">
          <NavLink
            to="invoice"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <PiListBulletsBold className="mr-2 text-[18px]" />Invoice
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink
            to="Blogs"
            className={({ isActive }) =>
              isActive
                ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
            }
          >
            <PiListBulletsBold className="mr-2 text-[18px]" />Blogs
          </NavLink>
        </div>
        
        {/* <p className="text-[16px] font-semibold text-white">DASHBOARD</p> */}
      </div>
      <div
            onClick={handleClick}
            className="cursor-pointer relative pl-2 pr-2 flex items-center justify-center"
          >
            <div className="rounded-full w-[40px] flex items-center justify-center h-[40px] bg-[white] border">
              <FaUserAlt className="text-[25px] text-[#ADB5BD]" />
            </div>

            <p className="text-[16px] text-white ml-2">{cabOfficer.owner}</p>

            <div
              onClick={handleLogout}
              className={`${
                dropdown ? "flex" : "hidden"
              } cursor-pointer absolute rounded-xl bg-[white] border border-white items-center justify-center top-[45px] z-30 right-0 pt-2 pb-2 w-[160px] shadow-2xl`}
            >
              <div className="absolute right-[10px] top-[-10px] bg-white admin_nort h-[10px] w-[20px]"></div>
              <CgLogOut className="text-[20px]" />
              <p className="text-[16px] ml-2">Logout</p>
            </div>
          </div>
    </div>
  </div>
  )
}
