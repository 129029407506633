import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { toast } from "react-toastify";
import { useAuthContext } from "../store/auth";
import UpperNavbar from "./UpperNavbar";

const CheckoutPage = () => {
  const location = useLocation();
  const {user} = useAuthContext()
  const { formData, distance, fare } = location.state || {};
  let navigate = useNavigate();

  const [checkoutData, setCheckoutData] = useState({
    name: user?.user_fname || "",
    phoneNumber: "",
    email_address: "",
    payment_type: "card",
    message: "",
  });

  // Load initial jobCount from localStorage or start with 1
  const [jobCount, setJobCount] = useState(() => {
    const savedCount = localStorage.getItem("jobCount");
    return savedCount ? parseInt(savedCount, 10) : 1;
  });

  useEffect(() => {
    localStorage.setItem("jobCount", jobCount);
  }, [jobCount]);
  useEffect(()=>{
    console.log(formData)
  },[])
  const handleCheckoutChange = (e) => {
    const { name, value } = e.target;
    setCheckoutData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const generateJobId = () => {
    const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "");
    const nextJobNumber = jobCount; // Use current jobCount
    const newJobId = `${currentDate}-${nextJobNumber}`;
    setJobCount((prevCount) => prevCount + 1); // Increment jobCount for the next job

    console.log("Generated Job ID:", newJobId); // Debugging line
    console.log("Next Job Count:", jobCount + 1); // Debugging line

    return newJobId;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(checkoutData,'checkOut Data');
    
    try {
      const jobId = generateJobId();
      const dataToSend = { ...formData, distance, fare, ...checkoutData, jobId };

      toast.promise(
        axios.post("https://api.minicaberz.com/api/form/", dataToSend),
        {
          pending: 'Booking your job...',
          success: 'Job booked successfully!',
          error: 'Failed to book the job. Please try again.'
        }
      ).then(response => {
        if (formData.route === "return") {
          const returnTripData = {
            ...dataToSend,
            pickupLocation: formData.dropLocation,
            dropLocation: formData.pickupLocation,
            date: formData.returnDate,
            time: formData.returnTime,
            carType: formData.carType,
            distance,
            fare,
            jobId: generateJobId(), // Generate a new jobId for the return trip
          };

          axios.post("https://api.minicaberz.com/api/form/", returnTripData)
            .then(() => {
              toast.success("Return trip booked successfully!");
            })
            .catch((error) => {
              console.error("Error booking return trip:", error.response?.data || error.message);
              toast.error("Failed to book the return trip. Please try again.");
            });
        }

        navigate("/");
      });
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      toast.error("Failed to book the job. Please try again.");
    }
  };

  return (
    <div className="w-[100%] m-0 p-[0.1px]">
      <UpperNavbar />
      <Navbar />
      <div className="container flex justify-between flex-wrap-reverse sm:flex-nowrap items-start mt-16 pt-6 pb-6">
        <div className="w-[95%] sm:w-[45%] mt-4 p-4 bg-white rounded-lg">
          <h3 className="text-[32px] font-bold mb-2 text-[#253754]">
            Passenger Detail
          </h3>
          <div className="w-[100%] border-t-2 border-black mb-3"></div>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between">

            <label className="block mb-2 w-[48%]">
              <p className="text-gray-700">Name</p>
              <input
                type="text"
                name="name"
                value={checkoutData.name}
                onChange={handleCheckoutChange}
                placeholder="Your Name"
                className="w-full p-2 border rounded"
                required
              />
            </label>
            <label className="block mb-2 w-[48%]">
              <p className="text-gray-700">Phone Number</p>
              <input
                type="text"
                name="phoneNumber"
                value={checkoutData.phoneNumber}
                onChange={handleCheckoutChange}
                placeholder="Your Phone Number"
                className="w-full p-2 border rounded"
                required
                />
            </label>
                </div>
                <div className="flex justify-between">

            <label className="block mb-2 w-[48%]">
              <p className="text-gray-700">Email Address</p>
              <input
                type="text"
                name="email_address"
                value={checkoutData.email_address}
                onChange={handleCheckoutChange}
                placeholder="Email"
                className="w-full p-2 border rounded"
                required
                />
            </label>
            <label className="block mb-2 w-[48%]">
              <p className="text-gray-700">Payment Type</p>
              <select name="payment_type" value={checkoutData.payment_type} onChange={handleCheckoutChange} className="w-full p-2 border rounded" id="">
                <option value="card">Card</option>
                <option value="cash">Cash</option>
              </select>
            </label>
                </div>
            <label className="block mb-2">
              <p className="text-gray-700">Special Instruction</p>
              <textarea
                name="message"
                value={checkoutData.message}
                onChange={handleCheckoutChange}
                placeholder="Message for the driver: i.e. Flight No., Wheel chair access, Pet Friendly. etc....."
                className="w-full p-2 border rounded"
              />
            </label>
            <button
              type="submit"
              className="w-full mt-4 p-2 bg-[#0061f2] rounded-full text-white"
            >
              Confirm Booking
            </button>
          </form>
        </div>
        <div className="w-[95%] sm:w-[45%] mt-4 p-4 text-[#253754]">
          <h3 className="text-[32px] font-bold mb-2">Ride Detail</h3>
          <div className="w-[100%] border-t-2 border-black mb-3"></div>
          <div className="w-[100%] border-b-2 border-black pb-3 flex justify-between items-center">
            <p className="text-[20px] font-semibold">Pickup Location</p>
            <p className="text-[16px] font-semibold">
              {formData.pickupLocation}
            </p>
          </div>
          <div className="w-[100%] border-b-2 border-black pt-3 pb-3 flex justify-between items-center">
            <p className="text-[20px] font-semibold">Drop Location</p>
            <p className="text-[16px] font-semibold">{formData.dropLocation}</p>
          </div>
          <div className="w-[100%] border-b-2 border-black pt-3 pb-3 flex justify-between items-center">
            <p className="text-[20px] font-semibold">Car Type</p>
            <p className="text-[16px] font-semibold">{formData.carType}</p>
          </div>
          <div className="w-[100%] border-b-2 border-black pt-3 pb-3 flex justify-between items-center">
            <p className="text-[20px] font-semibold">Distance</p>
            <p className="text-[16px] font-semibold">{distance} miles</p>
          </div>
          <div className="w-[100%] border-b-2 border-black pt-3 pb-3 flex justify-between items-center">
            <p className="text-[20px] font-semibold">Total Fare</p>
            <p className="text-[16px] font-semibold">£ {parseFloat(fare).toFixed(2)}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
