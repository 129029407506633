import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import AddFareForm from "./AddFareForm";
import { toast } from "react-toastify";

function AdminCoveragePrice() {
  const [fares, setFares] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editFareData, setEditFareData] = useState(null); // For editing fares

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("adminToken");

      const fareResponse = await axios.get("https://api.minicaberz.com/api/admin/fares", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFares(fareResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Open modal for adding new fare
  const handleAdd = () => {
    setEditFareData(null); // Ensure no data is being edited
    setOpenModal(true);
  };

  // Open modal for editing fare
  const handleEdit = (fare) => {
    setEditFareData(fare); // Pass fare data to the modal
    setOpenModal(true);
  };

  // Delete a fare
  const handleDelete = async (fareId) => {
    try {
      await axios.delete(`https://api.minicaberz.com/api/fares/${fareId}`);
      toast.success("Fare deleted successfully");
      fetchData(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete fare:", error);
      alert("Failed to delete fare");
    }
  };

  return (
    <div className="flex flex-col w-[100%] pt-[75px] bg-[#E9E9EB] h-[100vh]">
      <div className="w-[100%]">
        <div className="w-[100%] flex justify-between items-center pt-3 pl-10 pr-6">
          <p className="text-[24px] font-semibold">DISTANCE PRICES</p>
        </div>
        <div className="w-[100%] flex justify-between pt-5 pb-20 pl-6 pr-6"></div>
        <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
          <div className="w-[100%] flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4 mb-4">Distance Prices</h2>
          <button onClick={handleAdd} className="text-[13px] text-white rounded-lg mb-4 mr-2 bg-[#95A3EE] p-2">Add Fare</button>
          </div>
          <table className="w-[100%] bg-white border border-gray-200">
            <thead className="w-[100%]">
              <tr className="w-[100%] bg-[#2649AD] text-white text-[14px] font-semibold text-left">
                <th className="py-1 px-4 border">MILAGE RANGE</th>
                <th className="py-1 px-4 border-2">CAR TYPE</th>
                <th className="py-1 px-4 border-2">1st MILE</th>
                <th className="py-1 px-4 border-2">PER MILE</th>
                <th className="py-1 px-4 border-2">ACTION</th>
              </tr>
              
            </thead>
            <tbody>
              {fares
                ? fares.map((job, index) => (
                    <tr key={index} className={`text-left text-[14px] ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                      <td className="py-1 px-4 border-2">{job.milesFrom} - {job.milesTo}</td>
                      <td className="py-1 px-4 border-2">{job.carType}</td>
                      <td className="py-1 px-4 border-2">£{job.firstMilePrice}</td>
                      <td className="py-1 px-4 border-2">£{job.perMilePrice}</td>
                      <td className="py-1 px-4 border-2">
                        <button onClick={() => handleEdit(job)} className="mr-4 text-blue-500">
                      <FaEdit />
                    </button>
                    <button onClick={() => handleDelete(job._id)} className="text-red-500">
                      <FaTrash />
                    </button>
                      </td>
                    </tr>
                  ))
                : null}
              
            </tbody>
          </table>
        </div>
      </div>
      <AddFareForm openModal={openModal} setOpenModal={setOpenModal} fetchData={fetchData} editFareData={editFareData} />
    </div>
  );
}

export default AdminCoveragePrice;

