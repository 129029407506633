import React from 'react'
import { BiTaxi } from "react-icons/bi";
import { GiCommercialAirplane } from "react-icons/gi";
import { IoBriefcase } from "react-icons/io5";

export default function Services() {
  return (
    <div className='w-[100%] pt-7 pb-12 flex items-center justify-center'>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px]">
        <p className='text-[35px] text-center font-bold'>Our Services</p>
<div id="services" class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white">
    <div class="w-[100%]">
        <div class="flex flex-wrap flex-row -mx-4 text-center">
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s">
                
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block text-gray-900 mb-4">
                        
                        <BiTaxi className='text-[35px]' />
                    </div>
                    <h3 class="text-lg leading-normal mb-2 font-semibold text-black">Taxi Service</h3>
                    <p class="text-gray-500">Minicaberz is dedicated to being there when you need us. With our extensive fleet of taxis, we are your go-to choice for a reliable and affordable "Local Taxi" service. Whether you're heading to work, a night out, or a special event, our drivers are just a call away.</p>
                </div>
              
            </div>
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" >
               
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block text-gray-900 mb-4">
                       
                        <GiCommercialAirplane className='text-[35px]' />
                    </div>
                    <h3 class="text-lg leading-normal mb-2 font-semibold text-black">Airport Taxi Service</h3>
                    <p class="text-gray-500">Traveling to or from the London Airports? Look no further than Minicabers. Our "Airport Transfer Taxi" service is punctual,reliable, and offers competitive rates for all major airports in London. We ensure you get there on time and stress-free.</p>
                </div>
         
            </div>
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" >
             
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block text-gray-900 mb-4">
                  
                    <IoBriefcase className='text-[35px]' />
                    </div>
                    <h3 class="text-lg leading-normal mb-2 font-semibold text-black">Executive Car Hire</h3>
                    <p class="text-gray-500">Our cab company is synonymous with reliability, quality, and affordability. Whether you need a cab for a short trip or a longer commute, Minicaberz is the cab company you can trust.Whether you need a cab for a short trip or a longer commute, Minicabers is the cab company you can trust.</p>
                </div>
             
            </div>
            
        </div>

    </div>
</div>
      </div>
    </div>
  )
}

