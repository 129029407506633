import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AdminBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [thumbnail, setThumbnail] = useState(null); // For thumbnail during edit

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://api.minicaberz.com/api/admin/adminblog"
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.minicaberz.com/api/admin/blogs/${id}`);
      setBlogs(blogs.filter((blog) => blog._id !== id));
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("title", selectedBlog.title);
    formData.append("author", selectedBlog.author);
    formData.append("content", selectedBlog.content);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail); // Include thumbnail if uploaded
    }

    try {
      await axios.put(
        `https://api.minicaberz.com/api/admin/blogs/${selectedBlog._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setBlogs(
        blogs.map((blog) =>
          blog._id === selectedBlog._id ? { ...blog, ...selectedBlog } : blog
        )
      );
      setIsEditing(false);
      setThumbnail(null); // Reset thumbnail
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4">Blogs List</h2>

      <div className="grid grid-cols-1 gap-4">
        {blogs.map((blog) => (
          <div key={blog._id} className="p-4 border rounded shadow-sm">
            <div className="flex space-x-5">
              <img
                src={`https://api.minicaberz.com${blog.thumbnail}`}
                alt={blog.title}
                className="w-[130px] h-[100px] object-cover"
              />
              <div className="h-[max-content]">
                <h3 className="text-lg font-bold">{blog.title}</h3>
                <p className="text-sm text-gray-600">Author: {blog.author}</p>
                <p>{blog.content}</p>
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-2">
              <button
                className="bg-green-500 text-white px-3 py-1 rounded"
                onClick={() => {
                  setSelectedBlog(blog);
                  setIsEditing(true);
                }}
              >
                Edit
              </button>
              <button
                className="bg-red-500 text-white px-3 py-1 rounded"
                onClick={() => handleDelete(blog._id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {isEditing && (
        <div className="fixed inset-0 flex items-center z-20 justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Edit Blog</h2>
            <form onSubmit={handleSaveEdit}>
              <input
                type="text"
                value={selectedBlog.title || ""}
                onChange={(e) =>
                  setSelectedBlog({ ...selectedBlog, title: e.target.value })
                }
                className="border p-2 mb-4 w-full"
                placeholder="Title"
              />
              <input
                type="text"
                value={selectedBlog.author || ""}
                onChange={(e) =>
                  setSelectedBlog({ ...selectedBlog, author: e.target.value })
                }
                className="border p-2 mb-4 w-full"
                placeholder="Author"
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setThumbnail(e.target.files[0])}
                className="border mb-4 w-full"
              />
              <ReactQuill
                theme="snow"
                value={selectedBlog.content || ""}
                onChange={(value) =>
                  setSelectedBlog({ ...selectedBlog, content: value })
                }
                className="h-[300px]"
                placeholder="Write your blog content here..."
              />
              <div className="flex justify-end gap-2 mt-14">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-3 py-1 rounded"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-3 py-1 rounded"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminBlogs;
