import React, { useEffect, useState } from "react";
import { IoDesktopOutline } from "react-icons/io5";
import { FaCarAlt } from "react-icons/fa";
import { TfiKey } from "react-icons/tfi";
import { MdWork } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PiListBulletsBold } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";
import { CiPen } from "react-icons/ci";
import { FaBars } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import NavImg1 from "../Images/output-onlinepngtools (1).png";
// import { FaUserAlt } from "react-icons/fa";


export default function AdminNavbar() {
  const [adminData, setadminData] = useState([]);
  const [dropdown, setdropdown] = useState(false);
  const navigate = useNavigate();

  const token1 = localStorage.getItem("adminToken");
  const fetchData = async () => {
    try {
      const adminResponse = await axios.get(
        "https://api.minicaberz.com/api/admin/getAdmin",
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      console.log(adminResponse.data);
      setadminData(adminResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  //   console.log(jobs);
  const handleClick = () => {
    if(dropdown){
      setdropdown(false)
    }
    else{
      setdropdown(true);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin/login");
  };
  const [openBar,setopenBar]= useState(false)
  return (
    <div className="fixed top-0 left-0 w-[100%] h-[8vh] bg-[#2649AD] flex items-center z-10">
      <div className="w-[100%] flex justify-between items-center bg-[#2649AD] pl-6 pr-6">
      <FaBars onClick={() => setopenBar(true)} className="text-[white] text-[25px] lg:hidden" />
      <div className={`${openBar ? "flex left-0" : "left-[-300px]"} absolute transition-all top-0 h-[100vh] bg-[#2649AD] w-[300px] justify-center`}>
      <div className="flex flex-col w-[80%]">
        <div className="flex justify-end mt-4 w-[100%]">
        <FaXmark onClick={() => setopenBar(false)} className="text-white text-[25px]"/>
        </div>
      <img src={NavImg1} className="w-[90%] mt-5 mb-4" alt="" />
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-4">
            <NavLink
              to="dashboard"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <IoDesktopOutline className="mr-2 text-[18px]" /> Dashboard
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-4">
            <NavLink
              to="jobs"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <MdWork className="mr-2 text-[18px]" /> Jobs
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-3">
            <NavLink
              to="distanceprice"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaMapMarkerAlt className="mr-2 text-[18px]" />Coverage & Rates
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-3">
            <NavLink
              to="cabofficer"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaCarAlt className="mr-2 text-[18px]" /> Cab Offices
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-3">
            <NavLink
              to="passengers"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaUserAlt className="mr-2 text-[18px]" /> Passengers
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-3">
            <NavLink
              to="invoice"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <PiListBulletsBold className="mr-2 text-[18px]" />Invoice
            </NavLink>
          </div>
          <div onClick={() => setopenBar(false)} className="cursor-pointer w-[max-content] mt-3">
            <NavLink
              to="createblog"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <CiPen className="mr-2 text-[18px]" />Blogs
            </NavLink>
          </div>
          
          {/* <p className="text-[16px] font-semibold text-white">DASHBOARD</p> */}
        </div>
      </div>
        <div className="hidden lg:flex items-center">
          <div className="cursor-pointer">
            <NavLink
              to="dashboard"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <IoDesktopOutline className="mr-2 text-[18px]" /> Dashboard
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="jobs"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <MdWork className="mr-2 text-[18px]" /> Jobs
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="distanceprice"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaMapMarkerAlt className="mr-2 text-[18px]" />Coverage & Rates
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="cabofficer"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaCarAlt className="mr-2 text-[18px]" /> Cab Offices
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="passengers"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <FaUserAlt className="mr-2 text-[18px]" /> Passengers
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="invoice"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <PiListBulletsBold className="mr-2 text-[18px]" />Invoice
            </NavLink>
          </div>
          <div className="cursor-pointer">
            <NavLink
              to="createblog"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] border-b-2 text-white border-white font-bold ml-4 mr-4 pt-2 pb-2"
                  : "flex items-center text-[17px] rounded-lg font-bold text-white ml-4 mr-4 pt-2 pb-2"
              }
            >
              <CiPen className="mr-2 text-[18px]" />Blogs
            </NavLink>
          </div>
          
          {/* <p className="text-[16px] font-semibold text-white">DASHBOARD</p> */}
        </div>
        <div
          onClick={handleClick}
          className="cursor-pointer relative pl-2 pr-2 flex items-center justify-center"
        >
          <div className="rounded-full w-[40px] flex items-center justify-center h-[40px] bg-[white] ">
            <FaUserAlt className="text-[25px] text-[#ADB5BD]" />
          </div>
          {adminData.map((data, index) => {
            return (
              <p className="text-[16px] text-white ml-2" key={index}>
                {data.admin_username}
              </p>
            );
          })}
          <div
            onClick={handleLogout}
            className={`${
              dropdown ? "flex" : "hidden"
            } cursor-pointer absolute rounded-xl bg-[white] items-center justify-center top-[45px] z-30 right-0 pt-2 pb-2 w-[160px] shadow-2xl`}
          >
            <div className="absolute right-[10px] top-[-10px] bg-white admin_nort h-[10px] w-[20px]"></div>
            <CgLogOut className="text-[20px]" />
            <p className="text-[16px] ml-2">Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
}

