import React from 'react'
import AboutImg1 from './Images/ex-image.jpg'

export default function About() {
  return (
    <div className='w-[100%] pt-14 pb-8 bg-[#F5F9FC] flex items-center justify-center'>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px]">
        <p className='text-[35px] font-bold text-center'>About Us</p>
<div class="py-16">  
  <div class="w-[100%] m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div class="md:5/12 lg:w-5/12">
          <img src={AboutImg1} alt="image" loading="lazy" width="" height="" />
        </div>
        <div class="md:7/12 lg:w-6/12">
          <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Welcome to Minicaber</h2>
          <p class="mt-6 text-gray-600">Your premier destination for reliable taxi and minicab services in London. We take pride in offering the best taxi and cab solutions, ensuring that you're never left stranded searching for "taxi " or "minicab Service nearest to you." Our extensive coverage area and commitment to customer satisfaction make us your go-to choice for transportation in London.</p>
          <p class="mt-4 text-gray-600">Minicaberz is your trusted taxi service in London, offering convenient minicab and cab solutions for all your transportation needs. Experience top-notch taxi service near you in London, including airport taxi options and local taxi service. Contact us now for an exceptional ride!</p>
          <p class="mt-4 text-gray-600">Minicaberz is dedicated to being there when you need us. With our extensive fleet of taxis, we are your go-to choice for a reliable and affordable "taxi near me" service. Whether you're heading to work, a night out, or a special event, our drivers are just a call away.</p>
        </div>
      </div>
  </div>
</div>
      </div>
    </div>
  )
}

