import React from "react";
import AdminJobs from "./AdminJobs";

function AdminAllJob() {
  return (
    <div className="flex flex-col pt-[75px] w-[100%] bg-[#E9E9EB]">
      <div className="w-[100%] pb-10">
        <div className="w-[100%] flex justify-between items-center pl-10 pr-6">
          <p className="text-[24px] font-semibold">JOBS</p>
        </div>
        
        <AdminJobs />
      </div>
      {/* <AddFareForm /> */}
    </div>
  );
}

export default AdminAllJob;
