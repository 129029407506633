import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";


function AdminPassenger() {
  const [users, setUsers] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [userForm, setUserForm] = useState({
    user_fname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",
    user_confirmpass: "",
  });
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token1 = localStorage.getItem("adminToken");

      const userResponse = await axios.get("https://api.minicaberz.com/api/admin/users", {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      });
      const adminResponse = await axios.get("https://api.minicaberz.com/api/admin/getAdmin", {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      });
      setAdminData(adminResponse.data);
      setUsers(userResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };

  const handleEdit = (user) => {
    setEditUser(user._id);
    setUserForm({
      user_fname: user.user_fname,
      user_lname: user.user_lname,
      user_email: user.user_email,
      user_phone: user.user_phone,
      user_confirmpass: user.user_confirmpass,
    });
  };

  const handleSaveEdit = async () => {
    try {
      const token1 = localStorage.getItem("adminToken");
      await axios.put(`https://api.minicaberz.com/api/admin/users/${editUser}`, userForm, {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      });
      fetchData();
      setEditUser(null);
      setUserForm({
        user_fname: "",
        user_lname: "",
        user_email: "",
        user_phone: "",
        user_confirmpass: "",
      });
    } catch (error) {
      console.error("Error updating user:", error.response?.data);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const token1 = localStorage.getItem("adminToken");
      await axios.delete(`https://api.minicaberz.com/api/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error.response?.data);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserForm({
      ...userForm,
      [name]: value,
    });
  };

  const handleDrop = (jobId) => {
    if (dropdown === jobId) {
      setDropdown(null);
    } else {
      setDropdown(jobId);
    }
  };
  
  const dropdownRef = useRef(null);

  // Detect clicks outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // If clicked outside the dropdown, close it
        handleDrop(null);
      }
    }

    // Add event listener for mousedown (or click) event
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown]);

  return (
    <div className="flex flex-col h-[100vh] pt-[75px] w-[100%] bg-[#E9E9EB]">
      <div className="w-[100%]">
        <div className="w-[100%] flex justify-between items-center pt-3 pl-10 pr-6">
          <p className="text-[24px] font-semibold">PASSENGER</p>
         
        </div>
        <div className="w-[100%] flex justify-between pt-5 pb-20 pl-6 pr-6"></div>
        <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
          <h2 className="text-2xl font-semibold ml-4 mb-4">Passengers</h2>
          <table className="w-[100%] bg-white border border-gray-200">
            <thead>
              <tr className="bg-[#2649AD] text-white text-left text-[14px] font-medium">
                <th className="py-1 px-4 text-[14px] border-2">FIRST NAME</th>
                <th className="py-1 px-4 text-[14px] border-2">LAST NAME</th>
                <th className="py-1 px-4 text-[14px] border-2">EMAIL ADDRESS</th>
                <th className="py-1 px-4 text-[14px] border-2">PHONE NO.</th>
                <th className="py-1 px-4 text-[14px] border-2">PASSWORD</th>
                <th className="py-1 px-4 text-[14px] border-2">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index} className={`text-left ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                  {editUser === user._id ? (
                    <>
                      <td className="py-1 px-4 text-[14px] border-2">
                        <input
                          type="text"
                          name="user_fname"
                          value={userForm.user_fname}
                          onChange={handleChange}
                          className="border rounded px-2 py-1"
                        />
                      </td>
                      <td className="py-2 px-4 text-[14px] border-b">
                        <input
                          type="text"
                          name="user_lname"
                          value={userForm.user_lname}
                          onChange={handleChange}
                          className="border rounded px-2 py-1"
                        />
                      </td>
                      <td className="py-2 px-4 text-[14px] border-b">
                        <input
                          type="email"
                          name="user_email"
                          value={userForm.user_email}
                          onChange={handleChange}
                          className="border rounded px-2 py-1"
                        />
                      </td>
                      <td className="py-2 px-4 text-[14px] border-b">
                        <input
                          type="text"
                          name="user_phone"
                          value={userForm.user_phone}
                          onChange={handleChange}
                          className="border rounded px-2 py-1"
                        />
                      </td>
                      <td className="py-2 px-4 text-[14px] border-b">
                        <input
                          type="password"
                          name="user_confirmpass"
                          value={userForm.user_confirmpass}
                          onChange={handleChange}
                          className="border rounded px-2 py-1"
                        />
                      </td>
                      <td className="py-2 px-4 text-[14px] border-b">
                        <button
                          onClick={handleSaveEdit}
                          className="text-green-500 mr-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditUser(null)}
                          className="text-gray-500"
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="py-1 px-4 text-[14px] border-2">{user.user_fname}</td>
                      <td className="py-1 px-4 text-[14px] border-2">{user.user_lname}</td>
                      <td className="py-1 px-4 text-[14px] border-2">{user.user_email}</td>
                      <td className="py-1 px-4 text-[14px] border-2">{user.user_phone}</td>
                      <td className="py-1 px-4 text-[14px] border-2">{user.user_confirmpass}</td>
                      <td className="text-[14px] px-[15px] pt-1 pb-1 border-b text-left relative">
                      <button
                        onClick={() => handleDrop(user._id)}
                        className="text-[16px] text-left hover:editdrop hover:rounded-lg hover:bg-slate-100"
                      >
                        <BsThreeDotsVertical />
                      </button>
                      
                      {dropdown === user._id && (
                        <div ref={dropdownRef} className="editdrop absolute top-[30px] right-[40px] z-20 rounded-lg pt-2 pr-1 pl-1 pb-2 w-[150px] h-[100px] bg-white flex flex-col justify-start">
                          <button
                          onClick={() => handleEdit(user)}
                          className="text-[17px] p-1 ml-1 pl-2 pr-2 text-left hover:bg-slate-50"
                        >
                          Edit
                        </button>
                        
                        <button
                          onClick={() => handleDelete(user._id)}
                          className="text-[17px] p-1 ml-1 pl-2 pr-2 text-left hover:bg-slate-50"
                        >
                          Delete
                        </button>
                          
                        </div>
                      )}
                    </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminPassenger;

