import React from "react";
import { FaMoneyBill } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaBookOpenReader } from "react-icons/fa6";
import { FaCarAlt } from "react-icons/fa";

export default function AirportSec1() {

  return (
    <div className="w-[100%] mt-[80px]">
      <div className="w-[100%] h-[40vh] airport_bg relative flex items-center justify-center">
          <p className="text-[50px] text-white font-semibold">24 Hour Airport Transfer Taxi</p>
      </div>
      <div className="pt-10 pb-10">
        <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaStar className="text-[35px]" />
                <h2 className="text-lg font-semibold">Reliable</h2>
                <p className="mt-2 text-gray-600">
                  We monitor flight schedules to adjust your pick-up time in
                  case of delays or early arrivals.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaMoneyBill className="text-[35px]" />
                <h2 className="text-lg font-semibold">No Surge</h2>
                <p className="mt-2 text-gray-600">
                  We are not increasing the fares even in traffic routes , you
                  will pay the fare on what you have booked your journey.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaBookOpenReader className="text-[35px]" />
                <h2 className="text-lg font-semibold">Comfortable Vehicles</h2>
                <p className="mt-2 text-gray-600">
                  From standard cars to luxury options, we cater to individuals,
                  families, and groups.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaCarAlt className="text-[35px]" />
                <h2 className="text-lg font-semibold">Professional Drivers</h2>
                <p className="mt-2 text-gray-600">
                  All drivers are licensed and experienced, ensuring a safe and
                  comfortable journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

