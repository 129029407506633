import React, { useState,useEffect } from "react";
import NavImg1 from "./Images/logo (1).png";
import { LuLogIn } from "react-icons/lu";
import { FaUserPlus, FaUser } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { useAuthContext } from "../store/auth";
import { FiLogOut } from "react-icons/fi";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isLoggedIn, user } = useAuthContext();
  console.log(isLoggedIn,user, "hello");
  const [data, setData] = useState({
    user_fname: "",
    user_email: "",
  });

  console.log("frontend user ", user);

  const [userData, setUserData] = useState(true);

  if (userData && user) {
    setData({
      user_fname: user.user_fname,
      user_email: user.email,
    });
    setUserData(false);
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 46) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`w-full text-gray-700 bg-white fixed z-[111] ${
      isScrolled ? "top-0" : "top-[44px]"
    } dark:text-gray-200 dark:bg-gray-800 navshadow`}>
      <div className="flex flex-col pt-4 pb-4 h-[max-content] w-[95%] sm:w-[90%] 2xl:w-[1450px] mx-auto lg:items-center lg:justify-between lg:flex-row">
        <div className="flex flex-row items-center justify-between">
          <Link
            to={"/"}
            className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark:text-white focus:outline-none focus:shadow-outline"
          >
            <img src={NavImg1} className="w-[150px]" alt="" />
          </Link>
          <div className="flex lg:hidden">
            <button
              className="lg:hidden rounded-lg focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(!open)}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path
                  className={`${!open ? "" : "hidden"}`}
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
                <path
                  className={`${open ? "" : "hidden"}`}
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            {isLoggedIn ? (
              <div className="flex lg:hidden">
                <Link to={"/logout-passenger"}>
                  <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
                    <FiLogOut className="mr-2 hidden sm:flex" />
                    Logout
                  </button>
                </Link>
                <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
                  <FaUser className="mr-2 hidden sm:flex" />
                  {data.user_fname}
                </button>
              </div>
            ) : (
              <div className="flex lg:hidden">
                <Link to={"/signup-passenger"}>
                  <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
                    <FaUserPlus className="mr-2 hidden sm:flex" />
                    Sign Up
                  </button>
                </Link>
                <Link to={"/login-passenger"}>
                  <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center">
                    <LuLogIn className="mr-2 hidden sm:flex" />
                    Log In
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <nav className="hidden lg:flex flex-row pb-4 lg:pb-0 lg:justify-end">
          <NavLink to={"/"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold"
                  : "flex items-center text-[17px] rounded-lg font-bold"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm rounded-lg font-semibold text-gray-900 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              HOME
            </a>
          </NavLink>
          <NavLink to={"/airport-transfer"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold"
                  : "flex items-center text-[17px] rounded-lg font-bold"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm rounded-lg font-semibold dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 md:ml-4 md:mr-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              AIRPORT TRANSFERS
            </a>
          </NavLink>
          <NavLink to={"/about"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold"
                  : "flex items-center text-[17px] rounded-lg font-bold"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 md:ml-4 md:mr-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              ABOUT
            </a>
          </NavLink>
          <NavLink to={"/our-cars"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold"
                  : "flex items-center text-[17px] rounded-lg font-bold"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 md:ml-4 md:mr-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"

              // className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 md:ml-4 md:mr-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              OUR FLEET
            </a>
          </NavLink>
        </nav>
        {isLoggedIn ? (
          <div className="hidden lg:flex">
            <Link to={"/logout-passenger"}>
              <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
                <FiLogOut className="mr-2 hidden sm:flex" />
                Logout
              </button>
            </Link>
            {/* <Link to={"/logout-passenger"}> */}
            <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
              <FaUser className="mr-2 hidden sm:flex" />
              {data.user_fname}
            </button>
            {/* </Link>  */}
          </div>
        ) : (
          <div className="hidden lg:flex">
            <Link to={"/signup-passenger"}>
              <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center mr-2">
                <FaUserPlus className="mr-2 hidden sm:flex" />
                Sign Up
              </button>
            </Link>
            <Link to={"/login-passenger"}>
              <button className="bg-[#2953A1] text-[white] pl-2 pr-2 sm:pl-5 sm:pr-5 pt-1 pb-1 rounded-md font-semibold flex items-center">
                <LuLogIn className="mr-2 hidden sm:flex" />
                Log In
              </button>
            </Link>
          </div>
        )}
        <nav
          className={`flex-col  ${
            open ? "flex pb-5" : "hidden"
          }`}
        >
          <NavLink to={"/"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold w-[max-content] mt-4"
                  : "flex items-center text-[17px] rounded-lg font-bold mt-4"
              }>
            <a
              className="px-6 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              HOME
            </a>
          </NavLink>
          <NavLink to={"/airport-transfer"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold w-[max-content] mt-4"
                  : "flex items-center text-[17px] rounded-lg font-bold mt-4"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              AIRPORT TRANSFERS
            </a>
          </NavLink>
          <NavLink to={"/about"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold w-[max-content] mt-4"
                  : "flex items-center text-[17px] rounded-lg font-bold mt-4"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              ABOUT
            </a>
          </NavLink>
          <NavLink to={"/our-cars"} className={({ isActive }) =>
                isActive
                  ? "flex items-center text-[17px] rounded-lg bg-gray-200 font-bold w-[max-content] mt-4"
                  : "flex items-center text-[17px] rounded-lg font-bold mt-4"
              }>
            <a
              className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:text-white dark:hover:text-white dark:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="#"
            >
              OUR FLEET
            </a>
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;

