import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { HiMiniXMark } from "react-icons/hi2";

// Modal Component for displaying completed jobs
const JobModal = ({ isOpen, onClose, jobs, tableRef, handleExport }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-[90%] max-h-[90vh] overflow-y-auto">
        <div className="w-[100%] flex items-start justify-between ">
          <h2 className="text-2xl font-semibold">Completed Jobs</h2>
          <button onClick={onClose} className="text-[20px]">
            <HiMiniXMark />
          </button>
        </div>

        <div className="w-[100%]" ref={tableRef}>
          <table className="w-full mt-2 border">
            <thead>
              <tr className="bg-[#2649AD] text-white text-[14px] font-medium">
                <th className="py-1 text-left px-4 border-2">Job ID</th>
                <th className="py-1 text-left px-4 border-2">Pickup</th>
                <th className="py-1 text-left px-4 border-2">Drop Off</th>
                <th className="py-1 text-left px-4 border-2">Name</th>
                <th className="py-1 text-left px-4 border-2">Phone No</th>
                <th className="py-1 text-left px-4 border-2">Email</th>
                <th className="py-1 text-left px-4 border-2">Price</th>
                <th className="py-1 text-left px-4 border-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job,index) => (
                <tr key={job._id} className={`text-left text-[14px] ${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                }`}>
                  <td className="py-1 text-left px-4 border-2">{job.jobId}</td>
                  <td className="py-1 text-left px-4 border-2">{job.pickupLocation}</td>
                  <td className="py-1 text-left px-4 border-2">{job.dropLocation}</td>
                  <td className="py-1 text-left px-4 border-2">{job.name}</td>
                  <td className="py-1 text-left px-4 border-2">{job.phoneNumber}</td>
                  <td className="py-1 text-left px-4 border-2">{job.email_address}</td>
                  <td className="py-1 text-left px-4 border-2">£ {job.fare}</td>
                  <td className="py-1 text-left px-4 border-2">{job.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-[100%] mt-4 flex justify-end">
          <button
            onClick={handleExport}
            className="bg-green-500 text-white p-2"
          >
            Export to PDF
          </button>
        </div>
      </div>
    </div>
  );
};

function AdminInvoice() {
  const [cabOfficers, setCabOfficers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobStats, setJobStats] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRef = useRef();

  const token1 = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchCabOfficersAndStats = async () => {
      try {
        const cabOfficeResponse = await axios.get(
          "https://api.minicaberz.com/api/admin/getCabofficer",
          {
            headers: {
              Authorization: `Bearer ${token1}`,
            },
          }
        );
        const officers = cabOfficeResponse.data;

        const jobStatsPromises = officers.map(async (officer) => {
          const response = await axios.get(
            "https://api.minicaberz.com/api/admin/filterjobs",
            {
              params: {
                fromDate: "", // Default for all jobs
                toDate: "",
                cabOfficer: officer._id,
              },
            }
          );

          const completedJobs = response.data.jobs.filter(
            (job) => job.status === "Completed" && job.acceptedBy
          );

          const totalFare = completedJobs.reduce((acc, job) => acc + job.fare, 0);
          const commission = (totalFare * 10) / 100;
          const totalCompletedJobs = completedJobs.length;

          return {
            cabOffice: officer.cab_office_name,
            totalCompletedJobs,
            totalFare,
            commission,
            jobs: completedJobs,
          };
        });

        const stats = await Promise.all(jobStatsPromises);
        setJobStats(stats);
        setCabOfficers(officers);
      } catch (error) {
        console.error("Error fetching cab officers or jobs:", error);
      }
    };

    fetchCabOfficersAndStats();
  }, [token1]);

  const handleFilter = async () => {
    try {
      const selectedOfficerData = cabOfficers.find(
        (officer) => officer.cab_office_name === selectedOfficer
      );

      if (!selectedOfficerData) {
        console.error("Selected officer not found");
        return;
      }

      const cabOfficerId = selectedOfficerData._id;

      const response = await axios.get(
        "https://api.minicaberz.com/api/admin/filterjobs",
        {
          params: {
            fromDate,
            toDate,
            cabOfficer: cabOfficerId,
          },
        }
      );

      const completedJobs = response.data.jobs.filter(
        (job) => job.status === "Completed" && job.acceptedBy
      );

      const totalFare = completedJobs.reduce((acc, job) => acc + job.fare, 0);
      const commission = (totalFare * 10) / 100;
      const totalCompletedJobs = completedJobs.length;

      setJobStats([
        {
          cabOffice: selectedOfficer,
          totalCompletedJobs,
          totalFare,
          commission,
          jobs: completedJobs,
        },
      ]);
      setFilteredJobs(completedJobs);
    } catch (error) {
      console.error("Error filtering jobs:", error);
    }
  };

  const handleExport = () => {
    const input = tableRef.current;

    const heading = document.createElement("h2");
    heading.className = "text-2xl font-bold text-center mb-4 bg-red-500";
    heading.innerText = "Invoice Report";
    input.insertBefore(heading, input.firstChild);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice_report.pdf");

      input.removeChild(heading);
    });
  };

  return (
    <div className="w-[100%] pb-10 pt-[75px] bg-[#E9E9EB] h-[100vh]">
      <div className="w-[100%] flex justify-between items-center pt-3 pl-10 pr-6">
        <p className="text-[24px] font-semibold">INVOICE</p>
      </div>
      <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[45px] ml-[2.5%] bg-[white] border">
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold mb-4 pl-4">Caboffice Productivity</h2>
          <div className="filter-section">
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="border p-2 mr-4"
            />
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="border p-2 mr-4"
            />
            <select
              value={selectedOfficer}
              onChange={(e) => setSelectedOfficer(e.target.value)}
              className="border p-2 mr-4"
            >
              <option value="">Select Cab Offices</option>
              {cabOfficers.map((officer) => (
                <option key={officer._id} value={officer.cab_office_name}>
                  {officer.cab_office_name}
                </option>
              ))}
            </select>
            <button
              onClick={handleFilter}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Filter
            </button>
          </div>
        </div>

        <table className="w-full mt-2 border">
          <thead>
            <tr className="bg-[#2649AD] text-white text-[14px] font-medium">
              <th className="py-1 text-[14px] text-left px-4 border-x-2 border-y-2">Cab Office</th>
              <th className="py-1 text-[14px] text-left px-4 border-x-2 border-y-2">Completed Jobs</th>
              <th className="py-1 text-[14px] text-left px-4 border-x-2 border-y-2">Total Fare</th>
              <th className="py-1 text-[14px] text-left px-4 border-x-2 border-y-2">Commission</th>
              <th className="py-1 text-[14px] text-left px-4 border-x-2 border-y-2">View Jobs</th>
            </tr>
          </thead>
          <tbody>
            {jobStats.map((stat, index) => (
              <tr key={index} className={`text-left ${
                index % 2 === 0 ? "bg-gray-100" : "bg-white"
              }`}>
                <td className="py-1 text-[14px] px-4 border-x-2 border-y-2 text-left">{stat.cabOffice}</td>
                <td className="py-1 text-[14px] px-4 border-x-2 border-y-2 text-left">{stat.totalCompletedJobs}</td>
                <td className="py-1 text-[14px] px-4 border-x-2 border-y-2 text-left">£ {stat.totalFare}</td>
                <td className="py-1 text-[14px] px-4 border-x-2 border-y-2 text-left">£ {stat.commission}</td>
                <td className="py-1 text-[14px] px-4 border-x-2 border-y-2 text-left">
                  <button
                    onClick={() => {
                      setFilteredJobs(stat.jobs);
                      setIsModalOpen(true);
                    }}
                    className="bg-blue-500 text-white p-[2px] pl-2 pr-2 text-[14px] rounded"
                  >
                    View Jobs
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <JobModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        jobs={filteredJobs}
        tableRef={tableRef}
        handleExport={handleExport}
      />
    </div>
  );
}

export default AdminInvoice;

