import React from 'react'
import AdminNavbar from './AdminNavbar'
import { Outlet } from 'react-router-dom'

export default function AdminPage() {
  return (
    <div className='flex relative'>
      <AdminNavbar/>
      <Outlet />
    </div>
  )
}
