import React, { useEffect,useState } from "react";
import PassengerNavbar from "./PassengerNavbar";
import { useAuthContext } from "../../store/auth";
import briefcasImg from "../Images/briefcase.png";
import briefcasImg2 from "../Images/job.png";
import briefcasImg3 from "../Images/recycle-bin.png";

export default function PassengerPanel() {
  const { UserJobs, getuserJobs } = useAuthContext();

  useEffect(() => {
    getuserJobs(); // Fetch jobs when component mounts
  }, []);

  useEffect(() => {
    console.log(UserJobs); // Log UserJobs whenever it updates
  }, [UserJobs]);
  const [filter, setFilter] = useState("all"); // State for filter
  const [filteredJobs, setFilteredJobs] = useState([]);

  const newJobCount = UserJobs?.filter((job) => job.status === "Pending").length || 0;
  const activeJobCount = UserJobs?.filter((job) => job.status === "Accepted").length || 0;
  const completedJobCount = UserJobs?.filter((job) => job.status === "Completed").length || 0;
  const cancelledJobCount = UserJobs?.filter((job) => job.status === "cancel").length || 0;

  useEffect(() => {
    // Filter jobs based on the selected filter
    if (UserJobs) {
      const filtered = UserJobs.filter((job) => {
        if (filter === "all") return job;
        if (filter === "new") return job.status === "Pending";
        if (filter === "active") return job.status === "Accepted";
        if (filter === "completed") return job.status === "Completed";
        if (filter === "cancelled") return job.status === "cancel";
        return true;
      });
      setFilteredJobs(filtered);
    }
  }, [filter, UserJobs]);

  return (
    <div className="w-[100%] h-[max-content]">
      <div className="pt-[75px] w-[100%] bg-[#E9E9EB] h-[100vh] pb-2">
        <div className="w-[100%] pb-6 h-[max-content]">
          <div className="w-[100%] flex items-center pl-10 pr-6">
            <p className="text-[24px] font-semibold">DASHBOARD</p>
          </div>
          <div className="w-[100%] flex justify-between pt-4 pl-6 pr-6">
          {/* Your stats cards here */}
          <div onClick={() => setFilter("new")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">NEW</div>
              <p className="text-[20px] mt-1 font-[500]">{newJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#67CDEF] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("active")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">ACTIVE</div>
              <p className="text-[20px] mt-1 font-[500]">{activeJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E8613C] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("completed")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">COMPLETED</div>
              <p className="text-[20px] mt-1 font-[500]">{completedJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#F6D500] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg2} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("cancelled")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">CANCELLED</div>
              <p className="text-[20px] mt-1 font-[500]">{cancelledJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E6335B] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg3} alt="" />
            </div>
          </div>
        </div>
        </div>
        <div className="w-[95%] h-[52vh] custom-scrollbar rounded-xl pt-4 mt-[15px] ml-[2.5%] bg-[white] border">
          <h2 className="text-2xl font-semibold ml-4 mb-4"> Jobs</h2>
          <table className="w-[100%] bg-white border border-gray-200">
            <thead>
              <tr className="bg-[#2649AD] text-white text-[14px] font-medium text-left">
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  STATUS
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  TIME
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  PICKUP
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  VIA
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  DROP OFF
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  ROUTE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  CARTYPE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  MILES
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  PRICE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  PHONE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  NAME
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2">
                  MESSAGE
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredJobs &&
                filteredJobs.sort(
                  (a, b) => new Date(b.date) - new Date(a.date)
                ).map((job, index) => (
                  <tr
                    key={job._id}
                    className={`text-left ${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.status === "Approved" ? "Inprocessing" : job.status}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.time} {job.date}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.pickupLocation}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.viaLocation}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.dropLocation}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.route}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.carType}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.distance} miles
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      £ {job.fare}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.phoneNumber}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.name}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[15px] border-x-2 border-b-2">
                      {job.message}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  );
}

