import React,{useEffect} from "react";
import Navbar from "../Components/Navbar";
import CarsSec1 from "../Components/CarsSec1";
import CarsSec2 from "../Components/CarsSec2";
import Footer from "../Components/Footer";
import CarSec3 from "../Components/CarSec3";
import UpperNavbar from "../Components/UpperNavbar";

export default function Cars() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="relative p-[0.1px]">
      <UpperNavbar />
      <Navbar />
      <CarsSec1 />
      <CarsSec2 />
      <CarSec3/>
      <Footer />
    </div>
  );
}

