import React,{useState,useEffect} from 'react'
import { useAuthContext } from '../../store/auth';



export default function PassengerProfile() {

  const { isLoggedIn, user } = useAuthContext();
  console.log(isLoggedIn,user, "hello");
  const [data, setData] = useState({
    user_fname: "",
    user_email: "",
    user_lname: "",
    user_confirmpass: "",
    user_phone: "",
  });

  console.log("frontend user ", user);

  const [userData, setUserData] = useState(true);

  if (userData && user) {
    setData({
      user_fname: user.user_fname,
      user_email: user.user_email,
      user_lname: user.user_lname,
      user_confirmpass: user.user_confirmpass,
      user_phone: user.user_phone,
    });
    setUserData(false);
  }


  
  return (
    <div className="w-[100%] h-max">
    <div className="w-[100%] pt-[75px] bg-[#E9E9EB] pb-10 h-[100vh]">

        <p className='text-[24px] pl-[2.5%] font-semibold pb-6'>PROFILE</p>
        <div className="w-[95%] bg-white rounded-lg m-auto">
            {
                isLoggedIn ? (
                    <div className='h-[max-content] bg-yellow'>
                        <p className='text-white bg-[#2649AD] text-[18px] font-semibold p-2 text-center'>
                            Passenger Details
                        </p>
                    
                    <table className="border w-[100%] text-[#525f7f]">
            <tbody>
                <tr>
                    <th className="text-left p-2 border">Passenger Name:</th>
                    <td className="p-4 border">{data.user_fname} {data.user_lname}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Email Address :</th>
                    <td className="p-4 border">{data.user_email}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Passenger Password :</th>
                    <td className="p-4 border">{data.user_confirmpass}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Phone Number :</th>
                    <td className="p-4 border">{data.user_phone}</td>
                </tr>
                
            </tbody>
            </table>
                    </div>
                ) : null
            }
        </div>
    </div>
    </div>
  )
}

