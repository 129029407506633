import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import briefcasImg from "../Images/briefcase.png";
import briefcasImg2 from "../Images/job.png";
import briefcasImg3 from "../Images/recycle-bin.png";
import { useAuthContext } from "../../store/auth";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewJob from "./ViewJob";

function AdminJobs() {
  const token1 = localStorage.getItem("adminToken");
  const {
    jobs,
    fetchJobs,
    updateJob,
    deleteJob,
    updateJobstatus,
    passJobstatus,
  } = useAuthContext();
  const [editingJob, setEditingJob] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [ViewJobs, setViewJobs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [jobDetails, setJobDetails] = useState({
    time: "",
    date: "",
    pickupLocation: "",
    viaLocation: "",
    dropLocation: "",
    route: "",
    carType: "",
    distance: "",
    fare: "",
    userFirstName: "",
  });
  const [cabOffices, setCabOffices] = useState([]);

  useEffect(() => {
    const getCabOffices = async () => {
      try {
        const cabOfficeResponse = await axios.get(
          "https://api.minicaberz.com/api/admin/getCabofficer",
          {
            headers: {
              Authorization: `Bearer ${token1}`,
            },
          }
        );
        setCabOffices(cabOfficeResponse.data);
      } catch (error) {
        console.error("Error fetching cab offices:", error);
      }
    };

    fetchJobs(); // Fetch jobs
    getCabOffices();
  }, [fetchJobs, token1]);

  const handleEdit = (job) => {
    setEditingJob(job._id);
    setJobDetails({
      ...job,
      date: job.date.split("T")[0], // Set date in 'YYYY-MM-DD' format
  });
    setIsEditModalOpen(true);
    setDropdown(null); // Close dropdown// Open the modal when editing
  };

  const handleCancelEdit = () => {
    setEditingJob(null);
    setJobDetails({
      time: "",
      date: new Date().toISOString().split("T")[0],
      pickupLocation: "",
      viaLocation: "",
      dropLocation: "",
      route: "",
      carType: "",
      distance: "",
      fare: "",
      userFirstName: "",
    });
    setIsEditModalOpen(false);
    setDropdown(null); // Close the modal
  };

  const calculateDistance = async () => {
    try {
      const destinations = jobDetails.viaLocation
        ? `${jobDetails.viaLocation}|${jobDetails.dropLocation}`
        : jobDetails.dropLocation;

      const response = await axios.get(
        `https://api.minicaberz.com/api/distance?origins=${jobDetails.pickupLocation}&destinations=${destinations}`
      );

      const totalDistanceInMeters = response.data.totalDistance;

      if (typeof totalDistanceInMeters === "number") {
        const totalDistanceInMiles = (totalDistanceInMeters / 1609.34).toFixed(
          2
        );
        const roundedDistanceInMiles = Math.round(totalDistanceInMiles);
        return roundedDistanceInMiles;
      } else {
        console.error("Invalid distance data:", response.data);
      }
    } catch (error) {
      console.error("Error calculating distance:", error);
    }
  };

  const calculateFare = async (distance1, carType) => {
    try {
      const distanceInMiles = parseFloat(distance1);

      if (isNaN(distanceInMiles)) {
        throw new Error("Invalid distance value");
      }

      const response = await axios.get(
        `https://api.minicaberz.com/api/fares/calculate?carType=${carType}&miles=${distanceInMiles}`
      );

      const fare = response.data;
      let totalFare = fare.firstMilePrice;
      if (distanceInMiles > 1) {
        totalFare += (distanceInMiles - 1) * fare.perMilePrice;
      }
      return totalFare;
    } catch (error) {
      console.error("Failed to calculate fare:", error);
    }
  };

  const handleSaveEdit = async () => {
    await updateJob(editingJob, jobDetails);
    fetchJobs(); // Refresh the jobs list after updating
    handleCancelEdit(); // Reset editing state
    setDropdown(null); // Close dropdown
  };

  const handleDelete = async (jobId) => {
    await deleteJob(jobId);
    fetchJobs(); // Refresh the jobs list after deletion
    setDropdown(null); // Close dropdown
  };
  const handleCancelJob = async (jobId) => {
    try {
      await updateJobstatus(jobId, { status: "cancel" });
      fetchJobs(); // Refresh the jobs list after updating
      setDropdown(null); // Close dropdown
    } catch (error) {
      console.error("Failed to cancel job:", error);
    }
  };
  const handlePassJob = async (jobId) => {
    try {
      await passJobstatus(jobId, { status: "Approved" });
      fetchJobs(); // Refresh the jobs list after updating
      setDropdown(null); // Close dropdown
    } catch (error) {
      console.error("Failed to cancel job:", error);
    }
  };

  const handleView = async (jobId) => {
    const selectedJob = jobs.filter((job) => job._id === jobId);
    setViewJobs(selectedJob);
    setModalOpen(true);
    setDropdown(null); // Close dropdown
  };

  const closeModal = () => {
    setModalOpen(false);
    setViewJobs([]);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "date") {
      const formattedDate = new Date(value).toISOString().split("T")[0]; // Keep only 'YYYY-MM-DD'
      setJobDetails({ ...jobDetails, date: formattedDate });
      return;
  }
    const updatedJobDetails = {
      ...jobDetails,
      [name]: value,
    };

    if (
      name === "pickupLocation" ||
      name === "viaLocation" ||
      name === "dropLocation" ||
      name === "carType"
    ) {
      const distance1 = await calculateDistance();
      updatedJobDetails.distance = distance1;

      const totalFare1 = await calculateFare(
        distance1,
        name === "carType" ? value : jobDetails.carType
      );
      updatedJobDetails.fare = totalFare1;
    }

    setJobDetails(updatedJobDetails);
  };

  const getCabOfficeName = (cabOfficeId) => {
    const cabOffice = cabOffices.find((office) => office._id === cabOfficeId);
    return cabOffice ? cabOffice.owner : "Unknown";
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  const [filter, setFilter] = useState("New"); // State for filter
  const [filteredJobs, setFilteredJobs] = useState([]);

  const newJobCount =
    jobs?.filter((job) => job.status === "Pending").length || 0;
  const activeJobCount =
    jobs?.filter((job) => job.status === "Accepted").length || 0;
  const completedJobCount =
    jobs?.filter((job) => job.status === "Completed").length || 0;
  const cancelledJobCount =
    jobs?.filter((job) => job.status === "cancel").length || 0;

  useEffect(() => {
    // Filter jobs based on the selected filter
    if (jobs) {
      const filtered = jobs.filter((job) => {
        if (filter === "New")
          return job.status === "Pending" || job.status === "Approved";
        if (filter === "Active") return job.status === "Accepted";
        if (filter === "Completed") return job.status === "Completed";
        if (filter === "Cancelled") return job.status === "cancel";
        return true;
      });
      setFilteredJobs(filtered);
    }
  }, [filter, jobs]);
  const handleDrop = (jobId) => {
    if (dropdown === jobId) {
      setDropdown(null);
    } else {
      setDropdown(jobId);
    }
  };
  
  const dropdownRef = useRef(null);

  // Detect clicks outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // If clicked outside the dropdown, close it
        handleDrop(null);
      }
    }

    // Add event listener for mousedown (or click) event
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown]);

const truncateText = (text, wordLimit)=> {
      const words = text.split('');
      console.log(words,'hello');
      
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join('') + '...';
      }
      return text;
    }  

  return (
    <div className="flex flex-col w-[100%] bg-[#E9E9EB]">
      <div className="w-[100%] pb-10">
        <div className="w-[100%] flex justify-between pt-5 pb-20 pl-6 pr-6">
          <div
            onClick={() => setFilter("New")}
            className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300"
          >
            <div className="">
              <div className="text-[#8898aa] text-[14px]">NEW</div>
              <p className="text-[20px] mt-1 font-[500]">{newJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#67CDEF] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div
            onClick={() => setFilter("Active")}
            className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300"
          >
            <div className="">
              <div className="text-[#8898aa] text-[14px]">ACTIVE</div>
              <p className="text-[20px] mt-1 font-[500]">{activeJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E8613C] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div
            onClick={() => setFilter("Completed")}
            className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300"
          >
            <div className="">
              <div className="text-[#8898aa] text-[14px]">COMPLETED</div>
              <p className="text-[20px] mt-1 font-[500]">{completedJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#F6D500] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg2} alt="" />
            </div>
          </div>
          <div
            onClick={() => setFilter("Cancelled")}
            className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300"
          >
            <div className="">
              <div className="text-[#8898aa] text-[14px]">CANCELLED</div>
              <p className="text-[20px] mt-1 font-[500]">{cancelledJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E6335B] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg3} alt="" />
            </div>
          </div>
        </div>
        <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
          <div
            className={`${
              modalOpen
                ? "absolute top-0 left-0 bg-[#000000b2] h-[100%] w-[100%] flex justify-center"
                : "hidden"
            }`}
          >
            <ViewJob
              ViewJobs={ViewJobs}
              onClose={closeModal}
              getCabOfficeName={getCabOfficeName}
            />
          </div>
          <h2 className="text-2xl font-semibold ml-4 mb-4">{filter} Jobs</h2>
          <table className='w-full bg-white border border-gray-200'>
            <thead>
              <tr className="bg-[#2649AD] text-white text-[14px] font-medium text-left whitespace-nowrap">
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  STATUS
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  TIME
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  PICKUP
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  VIA
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  DROP OFF
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  ROUTE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  CARTYPE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  MILES
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  PRICE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  PHONE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  NAME
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  MESSAGE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  Payment_Type
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  CAB OFFICE
                </th>
                <th className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody>
            {filteredJobs &&
                filteredJobs.sort((a, b) => new Date(b.date) - new Date(a.date)).map((job,index) => (
                <tr key={job._id}
                className={`text-left whitespace-nowrap ${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                }`}>
                  {editingJob === job._id ? (
                    <><td className="py-1 text-[14px] px-1 border-x-2 border-b-2">
                    <input
                      type="text"
                      name="status"
                      value={jobDetails.status}
                      onChange={handleChange}
                      placeholder="Pickup Location"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      name="date"
                      value={jobDetails.date}
                      onChange={handleChange}
                      placeholder="Date"
                      className=" bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />

                    <input
                      type="time"
                      name="time"
                      value={jobDetails.time}
                      onChange={handleChange}
                      placeholder="Time"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="pickupLocation"
                      value={jobDetails.pickupLocation}
                      onChange={handleChange}
                      placeholder="Pickup Location"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="viaLocation"
                      value={jobDetails.viaLocation}
                      onChange={handleChange}
                      placeholder="Via Location"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="pickupLocation"
                      value={jobDetails.dropLocation}
                      onChange={handleChange}
                      placeholder="Drop Location"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="route"
                      value={jobDetails.route}
                      onChange={handleChange}
                      placeholder="Route"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="carType"
                      value={jobDetails.carType}
                      onChange={handleChange}
                      placeholder="Car Type"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="distance"
                      value={jobDetails.distance}
                      onChange={handleChange}
                      placeholder="Distance"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="Number"
                      name="fare"
                      value={jobDetails.fare}
                      onChange={handleChange}
                      placeholder="Fare"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={jobDetails.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={jobDetails.name}
                      onChange={handleChange}
                      placeholder="Name"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="message"
                      value={jobDetails.message}
                      onChange={handleChange}
                      placeholder="Message"
                      className="bg-gray-100 w-[144px] p-0 m-0 text-[15px] border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 transition-shadow duration-200 ease-in-out"
                    />
                  </td>
                  <td></td>
                  <td>
                    <div className="bg-gray-50 flex justify-end space-x-4 rounded-b-lg">
                      <button
                        onClick={handleSaveEdit}
                        className="bg-gradient-to-r from-blue-600 to-indigo-500 text-white text-[14px] px-2 py-1 rounded-md shadow-md hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-400 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-200 ease-in-out"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="bg-gray-500 text-white px-2 py-1 text-[14px] rounded-md shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-200 ease-in-out"
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                  </>
                  ) : (
                    <>
                      <td className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.status === "Approved" ? "Inprocessing" : job.status}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.time} {formatDate(job.date)}
                    </td>
                    <td title={job.pickupLocation} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {truncateText(job.pickupLocation,17)}
                    </td>
                    <td title={job.viaLocation} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {truncateText(job.viaLocation,17)}
                    </td>
                    <td title={job.dropLocation} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {truncateText(job.dropLocation,17)}
                    </td>
                    <td title={job.route} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.route}
                    </td>
                    <td title={job.carType} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.carType}
                    </td>
                    <td title={job.distance} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.distance} miles
                    </td>
                    <td title={job.fare} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      £ {parseFloat(job.fare).toFixed(2)}
                    </td>
                    <td title={job.phoneNumber} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.phoneNumber}
                    </td>
                    <td title={job.name} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {truncateText(job.name,17)}
                    </td>
                    <td title={job.message} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {truncateText(job.message,17)}
                    </td>
                    <td title={job.payment_type} className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {job.payment_type}
                    </td>
                    <td className="py-1  w-[max-content] text-[14px] px-[10px] border-x-2 border-b-2">
                      {getCabOfficeName(job.acceptedBy)}
                    </td>
                    <td className="text-[14px] px-[10px] pt-1 pb-1 border-b text-left relative">
                      <button
                        onClick={() => handleDrop(job._id)}
                        className="text-[16px] text-left hover:editdrop hover:rounded-lg hover:bg-slate-100"
                      >
                        <BsThreeDotsVertical />
                      </button>
                      <button
                      
                            className="text-[14px] bg-[#2649AD] text-white rounded-lg ml-1 pt-1 pb-1 pl-2 pr-2 text-left"
                            onClick={() => handlePassJob(job._id)}
                          >
                            Pass
                          </button>
                      {dropdown === job._id && (
                        <div ref={dropdownRef} className="editdrop absolute top-[30px] right-[40px] z-20 rounded-lg pt-2 pr-1 pl-1 pb-2 w-[150px] h-[150px] bg-white flex flex-col justify-start">
                          <button
                            onClick={() => handleView(job._id)}
                            className="text-[17px] p-1 ml-1 pl-2 pr-2 text-left hover:bg-slate-50"
                          >
                            View Job
                          </button>
                          <button
                            onClick={() => handleEdit(job)}
                            className="text-[17px] p-1 ml-1 pl-2 pr-2 text-left hover:bg-slate-50"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(job._id)}
                            className="text-[17px] p-1 mr-1 pl-3 pr-2 text-left hover:bg-slate-50"
                          >
                            Finish
                          </button>
                          <button
                            className="text-[17px] p-1 ml-1 pl-2 pr-2 text-left hover:bg-slate-50"
                            onClick={() => handleCancelJob(job._id)}
                          >
                            Cancel
                          </button>
                          
                        </div>
                      )}
                    </td>
                    
                    </>
                  )}
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminJobs;

