import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../store/auth';
import AdminBlogs from './AdminBlogs';
import { toast } from "react-toastify";

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const{blogs,setBlogs}= useAuthContext()
  const handleImageChange = (e) => {
    setThumbnail(e.target.files[0]); // Get the uploaded image file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('content', content);
    formData.append('thumbnail', thumbnail);

    try { 
      const response = await fetch('https://api.minicaberz.com/api/admin/blogs', {
        method: 'POST',
        body: formData, // Send FormData including file
      });
      const data = await response.json();
      console.log('Blog created:', data);
      setBlogs([data, ...blogs]);
      toast.success('Blog added successfully')
    } catch (error) {
      console.error('Error creating blog:', error);
    }
  };

  return (
    <div className="container mx-auto p-4 mt-[80px]">
      <h2 className="text-2xl font-bold mb-4">Create New Blog</h2>
      <form onSubmit={handleSubmit}>
      <div className="flex items-center justify-between">
      <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="border p-2 mb-4 w-[32%]"
          required
        />
        <input
          type="text"
          placeholder="Author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          className="border p-2 mb-4 w-[32%]"
          required
        />

        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="border mb-4 w-[32%]"
          required
        />

      </div>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          className="mb-4 h-[300px]"
          placeholder="Write your blog content here..."
        />

        <button type="submit" className="bg-[#2649AD] text-white p-2 rounded mt-[60px]">
          Submit
        </button>
      </form>
      <AdminBlogs />
    </div>
  );
};

export default CreateBlog;
