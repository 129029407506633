import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import AboutSec1 from '../Components/AboutSec1'
import AboutSec2 from '../Components/AboutSec2'
import UpperNavbar from '../Components/UpperNavbar'

export default function About() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <AboutSec1 />
      <AboutSec2 />
      <Footer />
    </div>
  )
}

