import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import UpperNavbar from "./UpperNavbar";
import img1 from "../Components/Images/heathrow-airport.avif";
import { GoDotFill } from "react-icons/go";
import CarImg1 from "./Images/9.webp";
import CarImg2 from "./Images/e.webp";
import CarImg3 from "./Images/tour.jpg";
import CarImg4 from "./Images/g.jpg";
import CarImg5 from "./Images/g.jpg";
import { Link } from "react-router-dom";

export default function HeathrowAirport() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[100%] relative bg-[#F3F4F6]">
      <UpperNavbar />
      <Navbar />
      <div className="pt-[75px] w-[100%]">
        <div className="w-[100%] h-[40vh] heathrow-bg relative">
          <div className="absolute top-0 left-0 h-[100%] w-[100%] flex items-center justify-center bg-[#000000ad]">
            <p className="text-[50px] font-semibold text-white">
              London Heathrow Airport
            </p>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto pt-10 pb-10">
        <div className="w-[100%] flex justify-center lg:justify-between items-center flex-wrap">
          <div className="w-[100%] sm:w-[95%] lg:w-[60%]">
            <p className="text-[25px] font-semibold">
              Heathrow Airport Transfer & Pickup – 24/7 Service by minicaberz
            </p>
            <p className="text-[18px] mt-3">
              Welcome to minicaberz, your trusted partner for Heathrow Airport
              transfer and pickup services, available 24 hours a day, 7 days a
              week. Whether you’re arriving at or departing from Heathrow, our
              reliable, professional drivers ensure a smooth and stress-free
              journey. minicaberz specializes in prompt, comfortable transfers
              tailored to meet the needs of every traveler—whether you’re
              traveling alone, with family, or for business.
            </p>
            <p className="text-[18px] mt-3">
              If you are in need of a airport taxi transfer from Heathrow
              Airport to London or a return taxi to Heathrow Airport, you are in
              the right place, there is no need to look further. minicaberz is
              an expert in airport taxi transfers, offering a wide range of
              services and vehicles to choose from. With multiple years
              experience in the London transport and minicab business, you can
              rest assured that your journey will be handled by professionals
              and planned to the last detail.
            </p>
            <p className="text-[18px] mt-3 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              15 Mins Waiting Time/Parking & Flight Tracking included
            </p>
            <p className="text-[18px] mt-1 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              Quick Pickup from outside terminal or upgrade for Meet & Greet
            </p>
            <p className="text-[18px] mt-1 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              Professional driver, clean car and reliable service every time
            </p>
            <p className="text-[18px] mt-1 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              Book a transfer within minutes and pay by card (online)
            </p>
            <p className="text-[18px] mt-1 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              Affordable prices and value for money on each occasion
            </p>
            <p className="text-[18px] mt-1 ml-4 flex items-center">
              <GoDotFill className="text-[18px] mr-2" />
              Vehicles for up to 8 passengers + luggage available
            </p>
          </div>
          <div className="w-[100%] sm:w-[90%] mt-10 lg:mt-0 lg:w-[35%] h-max">
            <img src={img1} className="w-[100%] h-max" alt="" />
          </div>
        </div>
        <div className="w-[100%]">
          <p className="text-[30px] font-semibold text-center mt-10">
            Airport transfer booking options at Heathrow London
          </p>
          <p className="text-center text-[18px] mt-6">
            When you are booking your London Heathrow Airport shuttle, minicaberz will
            give you various cars to choose from. If you want to keep it cheap,
            you can choose an economy or standard class vehicle. Or, if you’re
            travelling in a large group, you can choose a standard van which
            fits up to six people. If you have somewhere important to be, turn
            up in style in one of our first-class cars. If you have a child with
            you, be sure to add on a child seat during booking.
          </p>
          <section class="pt-10 lg:pt-[60px] pb-5">
            <div class="w-[100%]">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg1} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          Saloon Car
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        4 Passengers 2 Bags 2 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg2} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          Estate Car
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        4 Passengers 4 Bags 2 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg3} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          MPV Van
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        6 Passengers 4 Bags 4 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg4} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          8 SEATER
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        7 Passengers 6 Bags 6 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg5} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          9 SEATER
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        8 Passengers 6 Bags 6 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </section>
        </div>
        <div className="w-[100%] pt-5 pb-5 text-center call-bg">
        <p class="text-[40px] font-semibold text-white">
            CALL 020 8050 8797 OR BOOK ONLINE
        </p>
        <p class="text-[18px] mt-6 text-white">
            Always Pre Book Your Airport Trasnfer Taxi To Get Hassle Free Service
        </p>
        <Link to={"/"}>
        <button class="bg-[white] rounded-md pl-6 pr-6 pt-2 pb-2 mt-3">BOOK NOW</button>
        </Link>
        </div>
        <section class="w-[100%] pb-8">
      {/* <div class="section4-container"> */}
        <p class="text-[30px] text-center font-semibold mt-8">Why Choose minicaberz for Your Heathrow Airport Transfer</p>
        <div class="flex flex-wrap justify-center md:justify-between mt-8">
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              Looking for a dependable, affordable taxi service in London?
              minicaberz offers
              <span className="font-semibold">24/7 airport transfers</span> and
              <span className="font-semibold">minicab services</span>
              across London, including Heathrow, Gatwick, Stansted, and other
              major airports. With minicaberz, you get safe, comfortable rides at
              fixed rates—no hidden charges!
            </p>
            <p class="text-[18px] mt-4">
              Ready to ride?
              <span className="font-semibold"
                >Book your London airport taxi with minicaberz now!</span
              >
              Whether it’s for business, a family trip, or a quick transfer,
              we’ve got you covered. Visit minicaberz.co.uk to schedule your next
              ride. We provide round-the-clock airport transfer services across
              all London airports, ensuring you reach your destination on time,
              day or night.
            </p>
          </div>
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              <span className="font-semibold">Professional Drivers:</span> Our
              experienced, courteous drivers are ready to pick you up any time,
              day or night.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Easy Online Booking:</span> Simply
              book online for a smooth experience—no waiting, no hassle.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Affordable Rates:</span> We believe
              in transparency; all fares are fixed and affordable.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Reliable & Punctual:</span> Trust
              minicaberz to get you to your destination on time, every time.
            </p>
          </div>
        </div>
      {/* </div> */}
    </section>
        {/* <p className="text-[25px] font-semibold mt-4">
          Why Choose minicaberz for Your Heathrow Airport Transfer?
        </p>
        <ol>
          <li className="text-[18px] mt-3 font-normal">
            <span className="font-semibold text-[18px]">
              1. 24/7 Availability:
            </span>{" "}
            We understand that travel schedules can be unpredictable, which is
            why we operate around the clock, ensuring a hassle-free airport
            experience regardless of your flight time.
          </li>
          <li className="text-[18px] mt-3 font-normal">
            <span className="font-semibold text-[18px]">
              2. Professional, Friendly Chauffeurs:
            </span>{" "}
            Our knowledgeable chauffeurs are committed to providing a
            first-class experience, assisting you with luggage, guiding you to
            the vehicle, and ensuring a safe journey to or from the airport.
          </li>
          <li className="text-[18px] mt-3 font-normal">
            <span className="font-semibold text-[18px]">
              3. Comfort and Style:
            </span>{" "}
            minicaberz offers a premium fleet of vehicles that cater to every
            preference, from standard sedans to luxury options, ensuring comfort
            and relaxation after a long flight.
          </li>
          <li className="text-[18px] mt-3 font-normal">
            <span className="font-semibold text-[18px]">
              4. Timely Arrivals and Pickups:
            </span>{" "}
            With real-time flight tracking, we monitor your flight to adjust
            pickup times if there are delays or early arrivals, so we’re always
            there when you need us.
          </li>
        </ol>
        <p className="text-[25px] font-semibold mt-3">
          Seamless Booking Process
        </p>
        <p className="text-[18px] mt-3">
          Booking with minicaberz is straightforward and fast. Simply use our
          online booking system to schedule your Heathrow transfer in minutes.
          We offer transparent pricing with no hidden fees, ensuring you know
          exactly what to expect from start to finish.
        </p>
        <p className="text-[25px] font-semibold mt-3">
          Personalized Heathrow Airport Transfers
        </p>
        <p className="text-[18px] mt-3">
          Our Heathrow airport transfer services are customizable to suit your
          specific needs. Whether you need child seats, extra luggage space, or
          a specific type of vehicle, minicaberz ensures that your airport
          experience is tailored to perfection. Experience the difference of a
          dedicated Heathrow transfer service with minicaberz, where your
          comfort and convenience come first. Book your 24-hour Heathrow Airport
          transfer with us today and travel with ease!
        </p> */}
      </div>
      <Footer />
    </div>
  );
}

