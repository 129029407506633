import React from 'react'
import CabofficeDashboard from './CabofficeDashboard'

export default function CabofficeAllJobs() {
  return (
    <div>
      <CabofficeDashboard />
    </div>
  )
}
