import React from "react";
import CarImg1 from "./Images/9.webp";
import CarImg2 from "./Images/e.webp";
import CarImg3 from "./Images/tour.jpg";
import CarImg4 from "./Images/g.jpg";
import CarImg5 from "./Images/g.jpg";
import CarImg6 from "./Images/thumb.webp";

export default function CarsSec2() {
  return (
    <div className="w-[100%] pt-14 bg-[#F3F4F6]">
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto">
        <p className="text-center text-[18px] pb-6">
          At Minicaber, we take great pride in our diverse and extensive fleet
          of vehicles, catering to a wide range of transportation needs. From
          saloons to estates, MPVs to vans, and prestigious brands like Mercedes
          and BMW, our fleet is designed to deliver unparalleled comfort, style,
          and reliability for every occasion.
        </p>
        <section class="pt-10 lg:pt-[60px] pb-10 lg:pb-20 ">
          <div class="w-[100%]">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10 pb-7">
                  <img src={CarImg1} alt="image" class="w-full" />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        Saloon Car
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      Our saloon cars are perfect for individuals or small
                      groups, offering a comfortable and luxurious experience.
                      With spacious interiors and elegant designs, these
                      vehicles provide a smooth and stylish journey, whether
                      you're heading to a business meeting, airport transfer, or
                      a special event.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10">
                  <img src={CarImg2} alt="image" class="w-full" />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        Estate Car
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      For those in need of extra luggage space or traveling with
                      a larger group, our estate cars offer versatility without
                      compromising on luxury. With their extended cargo areas
                      and comfortable seating, these vehicles provide ample room
                      for both passengers and their belongings, ensuring a
                      convenient and enjoyable travel experience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10">
                  <img src={CarImg3} alt="image" class="w-full" />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        MPV Van
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      Our MPVs Tour Vans are designed to accommodate larger
                      groups, making them ideal for family outings, group
                      travel, or corporate events. With their spacious interiors
                      and flexible seating configurations, these vehicles offer
                      comfort, convenience, and plenty of legroom for everyone
                      to relax and enjoy the journey together.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10 pb-12">
                  <img src={CarImg4} alt="image" class="w-full" />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        MPV
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      Our MPVs are designed to accommodate larger groups, making
                      them ideal for family outings, group travel, or corporate
                      events. With their spacious interiors and flexible seating
                      configurations, these vehicles offer comfort, convenience,
                      and plenty of legroom for everyone to relax and enjoy the
                      journey together.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10">
                  <img src={CarImg5} alt="image" class="w-full" />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        Mercedes V Class
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      When it comes to group travel, our Mercedes V-Class offers
                      the perfect blend of space and elegance. With its
                      versatile seating configurations, luxurious interiors, and
                      advanced features, the Mercedes V-Class ensures that
                      everyone can travel in comfort and style. Whether it's a
                      family outing or a corporate retreat, our Mercedes V-Class
                      vehicles accommodate your needs with utmost convenience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                <div class="bg-white rounded-lg overflow-hidden mb-10 pb-7">
                  <img
                    src={CarImg6}
                    alt="image"
                    class="w-full"
                  />
                  <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                      >
                        Viano 9 Seater
                      </a>
                    </h3>
                    <p class="text-base text-body-color leading-relaxed mb-7">
                      Our Viano and Vito vans combine versatility and luxury,
                      making them perfect for both business and leisure travel.
                      With their spacious interiors, comfortable seating, and
                      advanced features, these vehicles provide a seamless blend
                      of practicality and sophistication. At Minicaber, we
                      strive to exceed our clients' expectations by offering a
                      diverse fleet of vehicles that cater to their specific
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

