import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaXmark } from "react-icons/fa6";
import { toast } from "react-toastify";

const AddFareForm = ({ openModal, setOpenModal, fetchData, editFareData }) => {
  const [formData, setFormData] = useState({
    milesFrom: '',
    milesTo: '',
    carType: '',
    firstMilePrice: '',
    perMilePrice: '',
  });

  useEffect(() => {
    if (editFareData) {
      setFormData(editFareData);
    }
  }, [editFareData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editFareData) {
        await axios.put(`https://api.minicaberz.com/api/fares/${editFareData._id}`, formData);
        toast.success('Fare updated successfully!');
        setFormData({
          milesFrom: '',
          milesTo: '',
          carType: '',
          firstMilePrice: '',
          perMilePrice: '',
        })
      } else {
        await axios.post('https://api.minicaberz.com/api/fares/add', formData);
        toast.success('Fare added successfully!');
        setFormData({
          milesFrom: '',
          milesTo: '',
          carType: '',
          firstMilePrice: '',
          perMilePrice: '',
        })
      }
      fetchData();
      setOpenModal(false);
    } catch (error) {
      console.error('Failed to submit fare:', error);
      toast.error('Failed to submit fare');
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={`${openModal ? 'flex' : 'hidden'} w-[100%] absolute top-0 left-0 h-[100vh] bg-[#000000ac] justify-center`}>
      <FaXmark onClick={handleClose} className='absolute top-16 right-2 text-[20px] text-[#e2e2e2] cursor-pointer'/>
      <form className="bg-white p-8 rounded-lg shadow-md w-1/2 h-max mt-[85px]" onSubmit={handleSubmit}>
        <h2 className="text-xl font-semibold mb-4">{editFareData ? 'Edit Fare' : 'Add New Fare'}</h2>
        <div className="flex justify-between">

        <div className="w-[30%]">
        <label className="block mb-2">Miles From:</label>
        <input type="number" name="milesFrom" value={formData.milesFrom} onChange={handleChange} className="w-full border px-4 py-2 rounded-md mb-4" />
        </div>
        <div className="w-[30%]">
        <label className="block mb-2">Miles To:</label>
        <input type="number" name="milesTo" value={formData.milesTo} onChange={handleChange} className="w-full border px-4 py-2 rounded-md mb-4" />
        </div>
        <div className="w-[30%]">
        <label className="block mb-2">Car Type:</label>
        <select name="carType" id="" value={formData.carType} onChange={handleChange} className="w-full border px-4 py-2 rounded-md mb-4">
          <option value="saloon">saloon</option>
          <option value="Estate">estate</option>
          <option value="MPV">mpv</option>
          <option value="Executive">executive</option>
          <option value="e-class">e-class</option>
          <option value="v-class">v-class</option>
          <option value="s-class">s-class</option>
        </select>
        </div>
        </div>

        <div className="flex justify-between">
        <div className="w-[46%]">

        <label className="block mb-2">First Mile Price:</label>
        <input type="number" name="firstMilePrice" value={formData.firstMilePrice} onChange={handleChange} className="w-full border px-4 py-2 rounded-md mb-4" />
        </div>
        <div className="w-[46%]">
        <label className="block mb-2">Per Mile Price:</label>
        <input type="number" name="perMilePrice" value={formData.perMilePrice} onChange={handleChange} className="w-full border px-4 py-2 rounded-md mb-4" />
        </div>
        </div>

        <div className="flex">

        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
          {editFareData ? 'Update Fare' : 'Add Fare'}
        </button>
        <p onClick={handleClose} className="bg-gray-500 ml-3 text-white px-4 py-2 w-max rounded-md cursor-pointer">
          Close
        </p>
        </div>
      </form>
    </div>
  );
};

export default AddFareForm;

