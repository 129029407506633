import React from 'react'
// import AdminNavbar from './AdminNavbar'
import { Outlet } from 'react-router-dom'
// import CabofficeDashboard from './CabofficeDashboard'
import CabOfficeNavbar from './CabOfficeNavbar'

export default function CabOfficePage() {
  return (
    <div className='flex flex-col relative'>
      {/* <AdminNavbar/> */}
      <CabOfficeNavbar />
      <Outlet />
    </div>
  )
}