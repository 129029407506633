import React from "react";

export default function AboutSec1() {

  return (
    <div className="w-[100%] mt-[80px]">
      <div className="w-[100%] h-[40vh] airport_bg flex items-center justify-center">
          <p className="text-[50px] text-white font-semibold">About us</p>
        </div>
    </div>
  );
}

