import React from "react";
import AdminJobs from "./AdminJobs";

function AdminPanel() {
  
  return (
    <div className="flex flex-col bg-[#E9E9EB] pt-[10vh] w-[100%] h-[100vh]">
      <p className="pl-9 pt-0 mt-0 text-[25px] font-semibold">Dashboard</p>
      <div className="w-[100%]">
      <AdminJobs />
      </div>
    </div>
  );
}

export default AdminPanel;
