import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUserAlt } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";
import { MdDirectionsCar, MdBusiness, MdReceipt, MdAccountCircle } from "react-icons/md";
import CabOfficeNavbar from "./CabOfficeNavbar";
import { useNavigate } from "react-router-dom";

export default function CabOfficeProfile() {
    const [cabOfficer, setcabOfficer] = useState({});
  const [activeTab, setActiveTab] = useState('cabOfficeDetail');
  const [dropdown, setdropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('cabofficeToken');

      try {
        const response = await axios.get('https://api.minicaberz.com/api/caboffice/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setcabOfficer(response.data.cabOffice);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchData();
  }, []);
  const handleLogout = () => {
    localStorage.removeItem('cabofficeToken');
    navigate('/');
  };

  const handleClick= ()=>{
        setdropdown(true)
      }

  

  const renderTabContent = () => {
    switch (activeTab) {
      case 'cabOfficeDetail':
        return <div>
            <div className="w-[100%] bg-[#2649AD] pt-2 pb-2 flex items-center justify-center text-white">Cab Office Detail</div>
            <table className="border w-[100%] text-[#525f7f]">
                <tbody>
                    <tr>
                        <th className="text-left p-2 border">Principal Contact for Correspondence/Owner :</th>
                        <td className="p-4 border">{cabOfficer.owner}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Name :</th>
                        <td className="p-4 border">{cabOfficer.cab_office_name}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Dispatch System Name :</th>
                        <td className="p-4 border">{cabOfficer.system_name}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Phone :</th>
                        <td className="p-4 border">{cabOfficer.phone_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Fax :</th>
                        <td className="p-4 border">{cabOfficer.fax_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Email :</th>
                        <td className="p-4 border">{cabOfficer.email_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Cab Office Address :</th>
                        <td className="p-4 border">{cabOfficer.address_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Date Business Commenced :</th>
                        <td className="p-4 border">{cabOfficer.date_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">License Number :</th>
                        <td className="p-4 border">{cabOfficer.licenseNo_caboffice}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Direct Cards Payments from Passengers :</th>
                        <td className="p-4 border">{cabOfficer.d_paypassenger ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <th className="text-left p-2 border">Account Jobs with minicabers :</th>
                        <td className="p-4 border">{cabOfficer.accountJobs_caboffice ? 'Yes' : 'No'}</td>
                    </tr>
                </tbody>
                </table>
            </div>;
      case 'fleetDetail':
        return <div>
        <div className="w-[100%] bg-[#2649AD] pt-2 pb-2 flex items-center justify-center text-white">Fleet Details</div>
        <table className="border w-[100%] text-[#525f7f]">
            <tbody>
                <tr>
                    <th className="text-left p-2 border">Number Of Saloon:</th>
                    <td className="p-4 border">{cabOfficer.no_of_saloon}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Number of Estate :</th>
                    <td className="p-4 border">{cabOfficer.no_of_estate}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Number of MPV :</th>
                    <td className="p-4 border">{cabOfficer.no_of_mpv}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Number of Others :</th>
                    <td className="p-4 border">{cabOfficer.no_of_othervehicles}</td>
                </tr>
                
            </tbody>
            </table>
        </div>;
      case 'invoiceDetail':
        return <div>
        <div className="w-[100%] bg-[#2649AD] pt-2 pb-2 flex items-center justify-center text-white">Invoice Details</div>
        <table className="border w-[100%] text-[#525f7f]">
            <tbody>
                <tr>
                    <th className="text-left p-2 border">Address:</th>
                    <td className="p-4 border">{cabOfficer.address_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">City :</th>
                    <td className="p-4 border">{cabOfficer.city_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Post Code :</th>
                    <td className="p-4 border">{cabOfficer.postcode_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Phone :</th>
                    <td className="p-4 border">{cabOfficer.phone_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Principal Contact for Correspondence :</th>
                    <td className="p-4 border">{cabOfficer.owner_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Fax :</th>
                    <td className="p-4 border">{cabOfficer.fax_invoice}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Email :</th>
                    <td className="p-4 border">{cabOfficer.email_invoice}</td>
                </tr>
                
            </tbody>
            </table>
        </div>;
      case 'businessReference':
        return <div>
        <div className="w-[100%] bg-[#2649AD] pt-2 pb-2 flex items-center justify-center text-white">Business Refferance Details</div>
        <table className="border w-[100%] text-[#525f7f]">
            <tbody>
                <tr>
                    <th className="text-left p-2 border">Company/Business Name:</th>
                    <td className="p-4 border">{cabOfficer.company_name}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Address:</th>
                    <td className="p-4 border">{cabOfficer.buis_address}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">City :</th>
                    <td className="p-4 border">{cabOfficer.buis_city}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Post Code :</th>
                    <td className="p-4 border">{cabOfficer.buis_postcode}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Phone :</th>
                    <td className="p-4 border">{cabOfficer.buis_phone}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Fax :</th>
                    <td className="p-4 border">{cabOfficer.buis_fax}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Email :</th>
                    <td className="p-4 border">{cabOfficer.buis_email}</td>
                </tr>
                
            </tbody>
            </table>
        </div>;
      case 'authUser':
        return <div>
        <div className="w-[100%] bg-[#2649AD] pt-2 pb-2 flex items-center justify-center text-white">User Login Details</div>
        <table className="border w-[100%] text-[#525f7f]">
            <tbody>
                <tr>
                    <th className="text-left p-2 border">Full Name:</th>
                    <td className="p-4 border">{cabOfficer.auth_fname} {cabOfficer.auth_lname}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Email:</th>
                    <td className="p-4 border">{cabOfficer.email_authController}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Password :</th>
                    <td className="p-4 border">{cabOfficer.auth_confirmpassCont}</td>
                </tr>
                <tr>
                    <th className="text-left p-2 border">Mobile :</th>
                    <td className="p-4 border">{cabOfficer.auth_mobile}</td>
                </tr>
                
                
            </tbody>
            </table>
        </div>;
      default:
        return null;
    }
  };
  return (
    <div className="flex flex-col relative pt-[75px] bg-[#E9E9EB]">
       {/* <CabOfficeNavbar /> */}
       <div className="w-[100%] pb-10">
         <div className="w-[100%] flex justify-between items-center pt-3 pb-3 pl-6 pr-6">
           
       <p className="text-[24px] font-semibold pl-4">Profile</p>
         </div>
         <div className="w-[100%] flex justify-between pt-8 pb-10 pl-6 pr-6">
           
         </div>
       {/* <CabOfficeProfile /> */}
    <div className="w-[95%] rounded-xl mt-[-65px] ml-[2.5%] bg-[white] border">
      <div className="flex justify-around rounded-xl bg-gray-50 border-b">
        <TabButton
          label="Cab Office Detail"
          icon={<MdAccountCircle />}
          active={activeTab === "cabOfficeDetail"}
          onClick={() => setActiveTab("cabOfficeDetail")}
        />
        <TabButton
          label="Fleet Detail"
          icon={<MdDirectionsCar />}
          active={activeTab === "fleetDetail"}
          onClick={() => setActiveTab("fleetDetail")}
        />
        <TabButton
          label="Invoice Detail"
          icon={<MdReceipt />}
          active={activeTab === "invoiceDetail"}
          onClick={() => setActiveTab("invoiceDetail")}
        />
        <TabButton
          label="Business Reference"
          icon={<MdBusiness />}
          active={activeTab === "businessReference"}
          onClick={() => setActiveTab("businessReference")}
        />
        <TabButton
          label="Auth User"
          icon={<FaUserAlt />}
          active={activeTab === "authUser"}
          onClick={() => setActiveTab("authUser")}
        />
      </div>

      <div className="p-6">{renderTabContent()}</div>
    </div>
       </div>
     </div>
  );
}
function TabButton({ label, icon, active, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`flex flex-col items-center justify-center p-4 text-center w-1/5 focus:outline-none ${
        active
          ? "text-blue-600 border-b-4 border-blue-600"
          : "text-gray-600 hover:text-blue-600"
      }`}
    >
      <div className="text-2xl mb-1">{icon}</div>
      <span className="text-sm">{label}</span>
    </button>
  );
}
